import styled from "@emotion/styled";
import { Box, Button, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC, useCallback } from "react";
import { ZChevron } from "../../Common/components/ZChevron";
import { useFilter } from "../hooks/useFilter";
import { FilterKind } from "../types/FilterKind";

interface Props {
  label: string;
  filterKind: FilterKind;
  iconComponent: OverridableComponent<SvgIconTypeMap>;
  testId?: string;
}

export const FilterButton: FC<Props> = (props) => {
  const filter = useFilter();
  const isSelected = filter.filterKind === props.filterKind;
  const setFilterKind = useCallback(() => {
    filter.setFilterKind(isSelected ? undefined : props.filterKind);
  }, [filter, isSelected, props.filterKind]);

  return (
    <SButton
      variant="contained"
      color={isSelected ? "secondary" : "inherit"}
      onClick={setFilterKind}
      data-testid={props.testId || null}
    >
      <Box whiteSpace="nowrap" display="flex" flexDirection="row">
        <props.iconComponent />
        <Box mx={0.5}>{props.label}</Box>
        <ZChevron isOpen={isSelected} />
      </Box>
    </SButton>
  );
};

const SButton = styled(Button)({
  fontWeight: "bold",
  letterSpacing: -0.2,
  borderRadius: 20,
  marginTop: 14,
  marginLeft: 14,
  paddingLeft: 14,
  paddingRight: 14,
});
