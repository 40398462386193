import { Timestamp } from "@quickcommerceltd/zephyr-types";
import { DateTime } from "luxon";

export function getCsvDate(timestamp?: Timestamp | null) {
  if (timestamp) {
    return DateTime.fromMillis(timestamp.toMillis()).toFormat(
      "dd/MM/yyyy hh:mm:ss"
    );
  }
}
