import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Divider, List } from "@mui/material";
import { FC, useMemo } from "react";
import { COLOR } from "../../App/constants/COLOR";
import { ZAccordion } from "../../Common/components/ZAccordion";
import { useVisibleThirdPartyProviders } from "../../Common/hooks/useVisibleThirdPartyProviders";
import { isRiderNotStarted } from "../../Rider/helpers/isRiderNotStarted";
import { useRiders } from "../../Rider/hooks/useRiders";
import { useTasks } from "../../Task/hooks/useTasks";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { SidebarEmptyListItem } from "./SidebarEmptyListItem";
import { SidebarRiderListItem } from "./SidebarRiderListItem";
import { SidebarTaskListItemWithTooltip } from "./SidebarTaskListItemWithTooltip";
import { SidebarTaskUnassignDropArea } from "./SidebarTaskUnassignDropArea";
import { SidebarThirdPartyProviderListItem } from "./SidebarThirdPartyProviderListItem";

export const SidebarNotStartedView: FC = () => {
  const warehouse = useSelectedWarehouse();
  const riders = useRiders((store) => store.riders).filter(isRiderNotStarted);
  const visibleThirdPartyProviders = useVisibleThirdPartyProviders();
  const enabledThirdPartyProviders = visibleThirdPartyProviders.filter(
    (provider) => warehouse?.thirdPartyProviders.includes(provider)
  );
  const areThirdPartyProvidersEnabled = !!enabledThirdPartyProviders.length;
  const idleRiders = riders
    .filter((r) => r.status === "IDLE_AT_STORE")
    .sort(
      (a, b) =>
        (a.arrivedAtStoreAt?.toMillis() || 0) -
        (b.arrivedAtStoreAt?.toMillis() || 0)
    );
  const assignedRiders = riders
    .filter((r) => r.status === "WAITING_FOR_PACKED_ORDER")
    .sort(
      (a, b) =>
        (a.arrivedAtStoreAt?.toMillis() || 0) -
        (b.arrivedAtStoreAt?.toMillis() || 0)
    );
  const pickingUpRiders = riders
    .filter((r) => r.status === "PICKING_UP")
    .sort(
      (a, b) =>
        (b.estimatedArrivalAtStoreAt?.toMillis() || 0) -
        (a.estimatedArrivalAtStoreAt?.toMillis() || 0)
    );
  const tasks = useTasks((state) => state.tasks);
  const sortedTasks = useMemo(
    () =>
      tasks
        .filter((t) => t.status === "UNASSIGNED")
        .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
        .sort((a, b) => (b.hasPriority ? 0 : 1) - (a.hasPriority ? 0 : 1)),
    [tasks]
  );

  return (
    <ZAccordion
      title="Not Started"
      titleIcon={<LocationOnIcon />}
      testId="SidebarNotStartedAccordian"
    >
      <List>
        <SidebarTaskUnassignDropArea>
          {sortedTasks.length === 0 && (
            <SidebarEmptyListItem
              message="No unassigned tasks"
              iconComponent={CheckCircleOutlineIcon}
              color={COLOR.green[500]}
            />
          )}
          {sortedTasks.map((task) => (
            <SidebarTaskListItemWithTooltip key={task.id} task={task} />
          ))}
        </SidebarTaskUnassignDropArea>
        <Divider sx={{ my: 1 }} />
        {!areThirdPartyProvidersEnabled && (
          <>
            {assignedRiders.map((rider) => (
              <SidebarRiderListItem key={rider.id} rider={rider} />
            ))}
            {idleRiders.map((rider) => (
              <SidebarRiderListItem key={rider.id} rider={rider} />
            ))}
            {pickingUpRiders.map((rider) => (
              <SidebarRiderListItem key={rider.id} rider={rider} />
            ))}
            {riders.length === 0 && (
              <SidebarEmptyListItem
                message="No assignable riders"
                iconComponent={HighlightOffIcon}
                color={COLOR.orange[500]}
              />
            )}
          </>
        )}
        {areThirdPartyProvidersEnabled && (
          <>
            {idleRiders.map((rider) => (
              <SidebarRiderListItem key={rider.id} rider={rider} />
            ))}
            {enabledThirdPartyProviders.map((provider) => (
              <SidebarThirdPartyProviderListItem
                thirdPartyProvider={provider}
              />
            ))}
            {pickingUpRiders.map((rider) => (
              <SidebarRiderListItem key={rider.id} rider={rider} />
            ))}
            {assignedRiders.map((rider) => (
              <SidebarRiderListItem key={rider.id} rider={rider} />
            ))}
          </>
        )}
      </List>
    </ZAccordion>
  );
};
