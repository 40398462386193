import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";
import { logError } from "../../Common/helpers/logError";
import { auth } from "./firebase";
import { getUserCollectionRef } from "./getCollectionRef";
import { getUserDocRef } from "./getDocRef";

export async function loginWithGoogle() {
  try {
    const googleProvider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, googleProvider);

    const user = res.user;
    const q = query(getUserCollectionRef(), where("id", "==", user.uid));
    const snapshot = await getDocs(q);

    if (snapshot.docs.length === 0 && user.email) {
      await setDoc(getUserDocRef(user.uid), {
        id: user.uid,
        name: user.displayName || user.uid,
        email: user.email,
        role: "DISPATCHER",
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    }
  } catch (error: any) {
    toast.error(error.message);
    logError(error);
  }
}
