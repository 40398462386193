import { Typography } from "@mui/material";
import { Task } from "@quickcommerceltd/zephyr-types";
import { Marker } from "@react-google-maps/api";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRiderFromTask } from "../../Rider/hooks/useRiderFromTask";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { getDistance } from "../helpers/getDistance";
import { getTaskMarkerIcon } from "../helpers/getTaskMarkerIcon";
import { toLatLng } from "../helpers/toLatLng";
import { useMap } from "../hooks/useMap";
import { MapInfoBox } from "./MapInfoBox";

interface Props {
  task: Task;
}

export const MapTaskMarker: FC<Props> = (props) => {
  const navigate = useNavigate();
  const selectedWarehouse = useSelectedWarehouse();
  const rider = useRiderFromTask(props.task);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const setFocusedMapTaskId = useMap((store) => store.setFocusedMapTaskId);
  const isFocused = useMap(
    (store) => !!store.focusedTaskIds?.includes(props.task.id)
  );
  const isUnfocused = useMap(
    (store) =>
      !!store.focusedTaskIds && !store.focusedTaskIds.includes(props.task.id)
  );

  const onMouse = useCallback(
    (isOver: boolean) => {
      setIsInfoVisible(isOver);
      setFocusedMapTaskId(isOver ? props.task.id : undefined);
    },
    [props.task.id, setFocusedMapTaskId]
  );

  return (
    <>
      <Marker
        opacity={isUnfocused ? 0.15 : 1}
        zIndex={2}
        animation={google.maps.Animation.DROP}
        position={toLatLng(props.task.address.location)}
        icon={getTaskMarkerIcon(isFocused, props.task.status, rider?.status)}
        onClick={() => navigate(`/tasks/${props.task.id}`)}
        onMouseOver={() => onMouse(true)}
        onMouseOut={() => onMouse(false)}
        title={`MapTaskMarker-${props.task.id}`}
      />
      {isInfoVisible && (
        <MapInfoBox
          isHidden={false}
          location={props.task.address.location}
          id={props.task.id}
        >
          <Typography fontWeight={700} fontSize={12} whiteSpace="nowrap">
            {props.task.address.streetAddress1}
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={500}
            sx={{ opacity: 0.75 }}
            whiteSpace="nowrap"
          >
            {selectedWarehouse
              ? getDistance(
                  props.task.address.location,
                  selectedWarehouse.address
                )
              : "Missing store"}
          </Typography>
        </MapInfoBox>
      )}
    </>
  );
};
