import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TaskStatus } from "@quickcommerceltd/zephyr-types";
import { DateTime } from "luxon";
import { FC, useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AppThemeProvider } from "../../App/components/AppThemeProvider";
import { ControlledDateTimePicker } from "../../Common/components/ControlledDateTimePicker";
import { ControlledSelect } from "../../Common/components/ControlledSelect";
import { ControlledTextField } from "../../Common/components/ControlledTextField";
import { logError } from "../../Common/helpers/logError";
import { getRiderName } from "../../Rider/helpers/getRiderName";
import { useAllRiders } from "../../Rider/hooks/useAllRiders";
import { useWarehouses } from "../../Warehouse/hooks/useWarehouses";
import { TASK_STATUSES } from "../constants/TASK_STATUSES";
import { exportTasks } from "../helpers/exportTasks";
import { getTaskStatusTranslation } from "../helpers/getTaskStatusTranslation";

type FormValues = {
  filterType: "none" | "warehouses" | "riders" | "taskId";
  taskStatuses: TaskStatus[];
  taskId: string;
  riderIds: string[];
  warehouseIds: string[];
  from: Date;
  to: Date;
};

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const TasksExportDialog: FC<Props> = (props) => {
  const [page, setPage] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [riders, isLoadingRiders] = useAllRiders();
  const warehouses = useWarehouses((state) => state.warehouses);
  const { control, handleSubmit, watch, getValues, setValue } =
    useForm<FormValues>({
      defaultValues: {
        taskStatuses: [...TASK_STATUSES],
        taskId: "",
        riderIds: [],
        warehouseIds: [],
        filterType: "none",
        from: DateTime.now().startOf("day").minus({ day: 1 }).toJSDate(),
        to: DateTime.now().startOf("day").toJSDate(),
      },
    });

  watch("filterType");

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    async (values) => {
      try {
        setIsExporting(true);
        await exportTasks({
          ...values,
          allRiders: riders,
          allWarehouses: warehouses,
          setPage,
        });
        props.close();
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      } finally {
        setIsExporting(false);
        setPage(0);
      }
    },
    [props, riders, warehouses]
  );

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <form>
          <DialogTitle>Export Tasks</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>Filtered by</DialogContentText>
            <Box display="flex" justifyContent="space-between">
              <Controller
                name="filterType"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="warehouses"
                      label="Warehouse"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="riders"
                      label="Riders"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="taskId"
                      label="Task ID"
                      control={<Radio />}
                    />
                  </RadioGroup>
                )}
              />
              {getValues("filterType") !== "none" && (
                <Button
                  size="small"
                  onClick={() => setValue("filterType", "none")}
                >
                  Clear Filters
                </Button>
              )}
            </Box>
            {getValues("filterType") === "warehouses" && (
              <ControlledSelect
                control={control}
                name="warehouseIds"
                label="Select Stores"
                allSelectedLabel="All Stores"
                helperText="At least one store is required"
                items={warehouses}
                getValue={(warehouse) => warehouse.id}
                getName={(warehouse) => warehouse.name}
                sx={{ mt: 1 }}
                multiple
                required
                canSelectAll
                fullWidth
              />
            )}
            {getValues("filterType") === "riders" && (
              <ControlledSelect
                control={control}
                name="riderIds"
                label="Select Riders"
                allSelectedLabel="All Riders"
                helperText="At least one rider is required"
                items={riders}
                getValue={(rider) => rider.id}
                getName={(rider) => getRiderName(rider)}
                sx={{ mt: 1 }}
                disabled={isLoadingRiders}
                multiple
                required
                canSelectAll
                fullWidth
              />
            )}
            {getValues("filterType") === "taskId" && (
              <ControlledTextField
                control={control}
                name="taskId"
                label="Task ID"
                sx={{ mt: 1 }}
                required
                fullWidth
              />
            )}
            <DialogContentText sx={{ mt: 3 }}>Task Details</DialogContentText>
            <ControlledSelect
              control={control}
              name="taskStatuses"
              label="Task Status"
              allSelectedLabel="All Task Statuses"
              disabledLabel="All Task Statuses"
              helperText="At least one status is required"
              items={TASK_STATUSES}
              getValue={(taskStatus) => taskStatus}
              getName={(taskStatus) =>
                getTaskStatusTranslation({ status: taskStatus })
              }
              sx={{ mt: 2 }}
              disabled={getValues("filterType") === "taskId"}
              required={getValues("filterType") !== "taskId"}
              multiple
              canSelectAll
              fullWidth
            />
            <DialogContentText sx={{ mt: 3 }}>Date Range</DialogContentText>
            <Box display="flex" flexDirection="row" sx={{ mt: 2 }}>
              <ControlledDateTimePicker
                control={control}
                name="from"
                label="From"
                disabled={getValues("filterType") === "taskId"}
                required={getValues("filterType") !== "taskId"}
                fullWidth
              />
              <ControlledDateTimePicker
                control={control}
                name="to"
                label="To"
                sx={{ ml: 2 }}
                disabled={getValues("filterType") === "taskId"}
                required={getValues("filterType") !== "taskId"}
                fullWidth
              />
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <LoadingButton
              variant="contained"
              loading={isExporting}
              onClick={handleSubmit(onSubmit)}
            >
              Download CSV
            </LoadingButton>
            <Button onClick={props.close} autoFocus>
              Cancel
            </Button>
            <Box display="flex" flex={1} />
            {page > 0 && (
              <Button onClick={props.close} disabled>
                Page: {page}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </AppThemeProvider>
  );
};
