import { useMemo } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { useMyUser } from "../../User/hooks/useMyUser";
import { useWarehouses } from "./useWarehouses";

export function useMyWarehouses() {
  const warehouses = useWarehouses((state) => state.warehouses);
  const user = useMyUser((state) => state.user);
  const hasPermission = useHasPermission();

  return useMemo(() => {
    if (hasPermission("READ_ALL_WAREHOUSES")) {
      return warehouses;
    } else {
      return warehouses.filter((w) => user?.warehouseIds?.includes(w.id));
    }
  }, [hasPermission, user?.warehouseIds, warehouses]);
}
