import { RiderStatus } from "@quickcommerceltd/zephyr-types";

export function getRiderStatusTranslation(riderStatus: RiderStatus): string {
  switch (riderStatus) {
    case "OFF_DUTY":
      return "Offline";
    case "PICKING_UP":
      return "Picking up";
    case "IDLE_AT_STORE":
      return "Idle";
    case "DELIVERING":
    case "IDLE_AT_CUSTOMER":
      return "In Transit";
    case "RIDING_BACK_TO_STORE":
      return "Returning";
    case "WAITING_FOR_PACKED_ORDER":
      return "Assigned";
  }
}
