import { TabContext, TabList, TabPanel } from "@mui/lab";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Tab,
} from "@mui/material";
import { ThirdPartyProvider } from "@quickcommerceltd/zephyr-types";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { AppThemeProvider } from "../../App/components/AppThemeProvider";
import { updateWarehouseCallable } from "../../Auth/callables/warehouse/updateWarehouseCallable";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { ControlledSwitch } from "../../Common/components/ControlledSwitch";
import { getThirdPartyProviderTranslation } from "../../Common/helpers/getThirdPartyProviderTranslation";
import { logError } from "../../Common/helpers/logError";
import { useVisibleThirdPartyProviders } from "../../Common/hooks/useVisibleThirdPartyProviders";
import { useWarehouses } from "../../Warehouse/hooks/useWarehouses";

type FormValues = {
  isAutoAssignmentEnabled: boolean;
  isStuartEnabled: boolean;
  isUberEnabled: boolean;
};

export const SettingsWarehouseUpsertDialog: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasPermission = useHasPermission();
  const navigate = useNavigate();
  const { warehouseId } = useParams<"warehouseId">();
  const warehouse = useWarehouses((state) =>
    state.warehouses.find((w) => w.id === warehouseId)
  );
  const isNew = warehouseId === "new";
  const defaultValues: FormValues = {
    isAutoAssignmentEnabled: !!warehouse?.isAutoAssignmentEnabled,
    isStuartEnabled: !!warehouse?.thirdPartyProviders.includes("STUART"),
    isUberEnabled: !!warehouse?.thirdPartyProviders.includes("UBER"),
  };
  const [selectedProviderTab, setSelectedProviderTab] =
    useState<ThirdPartyProvider>(warehouse?.thirdPartyProviders[0] || "STUART");
  const visibleThirdPartyProviders = useVisibleThirdPartyProviders();

  const { reset, control, handleSubmit } = useForm<FormValues>({
    defaultValues,
  });

  const closeDialog = useCallback(() => {
    navigate("/settings/stores");
    reset();
  }, [navigate, reset]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        setIsSubmitting(true);
        if (warehouse) {
          const thirdPartyProviders: ThirdPartyProvider[] = [];

          if (formValues.isStuartEnabled) {
            thirdPartyProviders.push("STUART");
          }
          if (formValues.isUberEnabled) {
            thirdPartyProviders.push("UBER");
          }

          await updateWarehouseCallable({
            warehouseId: warehouse.id,
            isAutoAssignmentEnabled: formValues.isAutoAssignmentEnabled,
            thirdPartyProviders,
          });
        } else {
          // TODO: add create warehouse callable
        }

        closeDialog();
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [closeDialog, warehouse]
  );

  const isLoading = isSubmitting;

  return (
    <AppThemeProvider mode="light">
      <Dialog onClose={closeDialog} fullWidth open>
        <form>
          <Box my={2} mx={3} display="flex" justifyContent="space-between">
            <DialogTitle sx={{ p: 0, alignSelf: "center" }}>
              {isNew ? "Create Store" : "Edit Store"}
            </DialogTitle>
          </Box>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              <Box>
                <ControlledSwitch
                  control={control}
                  disabled={isLoading}
                  label="Auto Assignment"
                  name="isAutoAssignmentEnabled"
                />
              </Box>
              {hasPermission("UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS") && (
                <TabContext value={selectedProviderTab}>
                  <TabList
                    onChange={(_, value) => setSelectedProviderTab(value)}
                  >
                    {visibleThirdPartyProviders.map((provider) => (
                      <Tab
                        key={provider}
                        label={getThirdPartyProviderTranslation(provider)}
                        value={provider}
                      />
                    ))}
                  </TabList>
                  {visibleThirdPartyProviders.map((provider) => (
                    <TabPanel key={provider} value={provider}>
                      <ControlledSwitch
                        control={control}
                        disabled={isLoading}
                        label="Enabled"
                        name={
                          provider === "STUART"
                            ? "isStuartEnabled"
                            : "isUberEnabled"
                        }
                      />
                    </TabPanel>
                  ))}
                </TabContext>
              )}
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {isNew ? "Create Store" : "Save Store"}
            </LoadingButton>
            <Button sx={{ mx: 2 }} onClick={closeDialog}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </AppThemeProvider>
  );
};
