import { Box, FormControlLabel, Paper, Stack, Switch } from "@mui/material";
import { FC, useCallback } from "react";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { DEFAULT_FEATURE_FLAGS } from "../constants/DEFAULT_FEATURE_FLAGS";
import { FEATURE_FLAGS } from "../constants/FEATURE_FLAGS";
import { useIsFeatureEnabled } from "../hooks/useIsFeatureEnabled";
import { FeatureFlag } from "../types/FeatureFlag";
import { SettingsTitleView } from "./SettingsTitleView";

export const SettingsFeatureFlagsView: FC = () => {
  const isFeatureEnabled = useIsFeatureEnabled();
  const localStorage = useLocalStorage();

  const onChange = useCallback(
    (featureFlag: FeatureFlag, value: boolean) => {
      localStorage.set({
        featureFlags: {
          ...DEFAULT_FEATURE_FLAGS,
          ...localStorage.data.featureFlags,
          [featureFlag]: value,
        },
      });
    },
    [localStorage]
  );

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <SettingsTitleView>Features</SettingsTitleView>
      <Box component={Paper} mt={5} p={2}>
        <Stack spacing={2}></Stack>
        {FEATURE_FLAGS.map((featureFlag) => (
          <Box key={featureFlag}>
            <FormControlLabel
              label={featureFlag}
              control={
                <Switch
                  checked={isFeatureEnabled(featureFlag)}
                  onChange={(event) =>
                    onChange(featureFlag, event.target.checked)
                  }
                />
              }
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
