import { Box, Paper } from "@mui/material";
import { InfoBox } from "@react-google-maps/api";
import { FC, ReactNode } from "react";
import { COLOR } from "../../App/constants/COLOR";
import { Coords } from "../../Common/types/Coords";
import { toLatLng } from "../helpers/toLatLng";

interface Props {
  location: Coords;
  isHidden: boolean;
  children: ReactNode;
  id?: string;
}

export const MapInfoBox: FC<Props> = (props) => {
  return (
    <InfoBox
      position={toLatLng(props.location)}
      options={{
        isHidden: props.isHidden,
        maxWidth: 260,
        closeBoxURL: "",
      }}
    >
      <Paper
        sx={{ border: `1px solid ${COLOR.rock[300]}` }}
        data-testid={`MapTaskInfoBox-${props.id}`}
      >
        <Box px={2} py={1}>
          {props.children}
        </Box>
      </Paper>
    </InfoBox>
  );
};
