import { Task, TaskCloneReason } from "@quickcommerceltd/zephyr-types";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { cloneTaskCallable } from "../../Auth/callables/task/cloneTaskCallable";
import { logError } from "../../Common/helpers/logError";

export function useCloneTask() {
  const [isCloningTask, setIsCloningTask] = useState(false);
  const navigate = useNavigate();

  const cloneTask = useCallback(
    async (task: Task, cloneReason: TaskCloneReason) => {
      try {
        setIsCloningTask(true);

        const clone = await cloneTaskCallable({
          taskId: task.id,
          cloneReason,
        });

        navigate(`/tasks/${clone.id}`);
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      } finally {
        setIsCloningTask(false);
      }
    },
    [navigate]
  );

  return useMemo(
    () => ({
      cloneTask,
      isCloningTask,
    }),
    [cloneTask, isCloningTask]
  );
}
