import { Timestamp } from "@quickcommerceltd/zephyr-types";
import { FC, useCallback, useEffect, useState } from "react";
import { getDiffInSeconds } from "../helpers/getDiffInSeconds";
import { getDiffTranslation } from "../helpers/getDiffTranslation";

interface Props {
  timestamp: Timestamp;
  translation: `${string}{diff}${string}`;
  shouldBeInThePast: boolean;
}

export const RelativeTimestampView: FC<Props> = (props) => {
  const getValue = useCallback(
    () =>
      props.translation.replace(
        "{diff}",
        getDiffTranslation(
          getDiffInSeconds(props.timestamp, props.shouldBeInThePast)
        )
      ),
    [props]
  );
  const [value, setValue] = useState(getValue());

  useEffect(() => {
    const interval = setInterval(() => setValue(getValue()), 1000);

    return () => clearInterval(interval);
  }, [getValue]);

  return <>{value}</>;
};
