import { Rider } from "@quickcommerceltd/zephyr-types";
import { useDrop } from "react-dnd";
import { DragItem } from "../../Sidebar/types/DragItem";
import { DropCollect } from "../../Sidebar/types/DropCollect";
import { DropResult } from "../../Sidebar/types/DropResult";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { validateAssignTaskToRider } from "../helpers/validateAssignTaskToRider";

export function useDropRider(rider: Rider) {
  const warehouse = useSelectedWarehouse();

  return useDrop<DragItem, DropResult, DropCollect>(
    () => ({
      accept: ["ASSIGNED_TASK", "UNASSIGNED_TASK"],
      drop: (_, monitor) => ({
        rider,
        error: validateAssignTaskToRider(rider, monitor.getItem(), warehouse)
          .error,
      }),
      collect: (monitor) => ({
        error: validateAssignTaskToRider(rider, monitor.getItem(), warehouse)
          .error,
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [rider, warehouse]
  );
}
