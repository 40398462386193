import { FC } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { AuthAlertView } from "../../Auth/components/AuthAlertView";
import { AuthLoggedInView } from "../../Auth/components/AuthLoggedInView";
import { AuthLoginView } from "../../Auth/components/AuthLoginView";
import { auth } from "../../Auth/helpers/firebase";
import { isEmailSupported } from "../../Auth/helpers/isEmailSupported";
import { LoadingView } from "../../Common/components/LoadingView";
import { useIsOffline } from "../hooks/useIsOffline";
import { AppOfflineView } from "./AppOfflineView";

export const AppAuthView: FC = () => {
  const [authState, isLoading] = useAuthState(auth);
  const isOffline = useIsOffline();

  if (isOffline) {
    return <AppOfflineView />;
  } else if (isLoading) {
    return <LoadingView />;
  } else if (authState) {
    if (!isEmailSupported(authState.email)) {
      return (
        <AuthAlertView title="Please use a 'quickcommerce.org' or 'tryzapp.com' email to sign in." />
      );
    } else {
      return <AuthLoggedInView />;
    }
  } else {
    return <AuthLoginView />;
  }
};
