import create from "zustand";
import { FilterKind } from "../types/FilterKind";

export interface FilterStore {
  filterKind?: FilterKind;
  setFilterKind: (filterKind?: FilterKind) => void;
}

export const useFilter = create<FilterStore>((set) => ({
  setFilterKind: (filterKind) => {
    set({ filterKind });
  },
}));
