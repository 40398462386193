import { Task } from "@quickcommerceltd/zephyr-types";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { unassignTaskCallable } from "../../Auth/callables/task/unassignTaskCallable";
import { logError } from "../../Common/helpers/logError";
import { useUnassignTaskFromThirdPartyProviderDialog } from "./useUnassignTaskFromThirdPartyProviderDialog";

export function useUnassignTask(): [(task: Task) => Promise<void>, boolean] {
  const [isUnassigningTask, setIsUnassigningTask] = useState(false);
  const { openDialog } = useUnassignTaskFromThirdPartyProviderDialog();

  const unassignTask = useCallback(
    async (task: Task) => {
      try {
        setIsUnassigningTask(true);

        if (task.thirdPartyProvider === "STUART") {
          await openDialog(task.id);
        } else {
          await unassignTaskCallable({ taskId: task.id });
        }
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      } finally {
        setIsUnassigningTask(false);
      }
    },
    [openDialog]
  );

  return [unassignTask, isUnassigningTask];
}
