import create from "zustand";

export interface SidebarSearchStore {
  query: string;
  debouncedQuery: string;
  isDebouncing: boolean;
  setQuery: (query: string) => void;
}

export const useSidebarSearch = create<SidebarSearchStore>((set, get) => ({
  query: "",
  debouncedQuery: "",
  isDebouncing: false,
  setQuery: (query) => {
    if (query === "") {
      set({ query, debouncedQuery: "", isDebouncing: false });
    } else {
      set({ query, isDebouncing: true });
      setTimeout(() => {
        if (get().query !== query || !get().isDebouncing) return;
        set({ debouncedQuery: query, isDebouncing: false });
      }, 500);
    }
  },
}));
