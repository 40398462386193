import { Task } from "@quickcommerceltd/zephyr-types";
import { useMemo } from "react";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { validateAssignTaskToRider } from "../helpers/validateAssignTaskToRider";
import { useRiders } from "./useRiders";

export function useAssignableRiders(task: Task) {
  const riders = useRiders((state) => state.riders);
  const warehouse = useSelectedWarehouse();

  return useMemo(
    () =>
      riders
        .filter((r) => validateAssignTaskToRider(r, task, warehouse).isValid)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [riders, task, warehouse]
  );
}
