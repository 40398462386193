import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
} from "@mui/material";
import {
  DispatcherCreateUserRequest,
  User,
  UserRole,
} from "@quickcommerceltd/zephyr-types";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AppThemeProvider } from "../../App/components/AppThemeProvider";
import { createUserCallable } from "../../Auth/callables/user/createUserCallable";
import { updateUserCallable } from "../../Auth/callables/user/updateUserCallable";
import { ControlledSelect } from "../../Common/components/ControlledSelect";
import { ControlledTextField } from "../../Common/components/ControlledTextField";
import { logError } from "../../Common/helpers/logError";
import { getUserRoleTranslation } from "../../User/helpers/getUserRoleTranslation";
import { useWarehouses } from "../../Warehouse/hooks/useWarehouses";
import { USER_ROLES } from "../constants/USER_ROLES";
import { SettingsUserDeleteDialog } from "./SettingsUserDeleteDialog";

type FormValues = {
  name: string;
  email: string;
  role: UserRole;
  warehouseIds: string[];
};

interface Props {
  user?: User;
  isOpen: boolean;
  close: () => void;
}

export const SettingsUserUpsertDialog: FC<Props> = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const warehouses = useWarehouses((state) => state.warehouses);
  const [isUserDeleteDialogOpen, setIsUserDeleteDialogOpen] = useState(false);
  const defaultValues: FormValues = {
    name: "",
    email: "",
    role: "DISPATCHER",
    warehouseIds: [],
  };

  const { reset, control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: { ...defaultValues, ...props.user },
  });

  const watchRole = watch("role");
  const isRoleAdmin = ["ADMIN", "CUSTOMER_SERVICE"].includes(watchRole);

  const closeDialog = useCallback(() => {
    props.close();
    reset();
  }, [props, reset]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const request: DispatcherCreateUserRequest = {
          role: formValues.role,
          name: formValues.name.trim(),
          email: formValues.email.trim(),
          warehouseIds: formValues.warehouseIds,
        };

        setIsSubmitting(true);
        if (props.user) {
          await updateUserCallable({ userId: props.user.id, ...request });
        } else {
          await createUserCallable(request);
        }

        closeDialog();
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [closeDialog, props.user]
  );

  const isLoading = isSubmitting;

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={closeDialog} fullWidth>
        <form>
          <Box my={2} mx={3} display="flex" justifyContent="space-between">
            <DialogTitle sx={{ p: 0, alignSelf: "center" }}>
              {props.user ? "Edit User" : "Create User"}
            </DialogTitle>
          </Box>
          <Divider />
          <DialogContent>
            <DialogContentText>User Details</DialogContentText>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <Box display="flex">
                <ControlledTextField
                  required
                  fullWidth
                  name="name"
                  label="User Name"
                  control={control}
                  disabled={isLoading}
                  sx={{ mr: 2 }}
                />
                <ControlledTextField
                  required
                  fullWidth
                  name="email"
                  label="User Email"
                  helperText="A quickcommerce or tryzapp email is required"
                  disabled={isLoading}
                  control={control}
                />
              </Box>
            </FormControl>
            <DialogContentText sx={{ mt: 2 }}>Permissions</DialogContentText>
            <ControlledSelect
              control={control}
              name="role"
              label="Role"
              items={USER_ROLES}
              disabled={isLoading}
              getValue={(role) => role}
              getName={(role) => getUserRoleTranslation(role)}
              sx={{ mt: 2 }}
              required
              fullWidth
            />
            <DialogContentText sx={{ mt: 2 }}>Store</DialogContentText>
            <ControlledSelect
              control={control}
              name="warehouseIds"
              label="Assigned to"
              disabled={isRoleAdmin || isLoading}
              disabledLabel="All Stores"
              required={!isRoleAdmin}
              helperText="At least one store is required"
              items={warehouses}
              getValue={(warehouse) => warehouse.id}
              getName={(warehouse) => warehouse.name}
              sx={{ mt: 2 }}
              canSelectAll
              multiple
              fullWidth
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {props.user ? "Save User" : "Create User"}
            </LoadingButton>
            <Button sx={{ mx: 2 }} onClick={closeDialog}>
              Cancel
            </Button>
            <Box display="flex" flex={1} />
            {props.user && (
              <Button
                color="error"
                onClick={() => setIsUserDeleteDialogOpen(true)}
              >
                Permanently remove User
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
      {props.user && (
        <SettingsUserDeleteDialog
          user={props.user}
          isOpen={isUserDeleteDialogOpen}
          close={() => {
            setIsUserDeleteDialogOpen(false);
            closeDialog();
          }}
        />
      )}
    </AppThemeProvider>
  );
};
