import {
  DispatcherCreateTaskRequest,
  Task,
} from "@quickcommerceltd/zephyr-types";
import { getCallable } from "../../helpers/getCallable";

export const createTaskCallable = getCallable<
  DispatcherCreateTaskRequest,
  Task
>("user/createTask");
