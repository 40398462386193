import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";

interface Props {
  size: number;
}

export const LoadingIcon: FC<Props> = (props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={props.size}
    >
      <CircularProgress size={Math.round(props.size * 0.7)} />
    </Box>
  );
};
