import { User } from "@quickcommerceltd/zephyr-types";
import { onSnapshot } from "firebase/firestore";
import toast from "react-hot-toast";
import create from "zustand";
import { getUserFromSnapshot } from "../../Auth/helpers/getDataFromDocumentSnapshot";
import { getUserDocRef } from "../../Auth/helpers/getDocRef";

export interface MyUserStore {
  user?: User;
  isInitializing: boolean;
  subscribe: (userId: string) => void;
  unsubscribe: () => void;
  unsubscribeFirestore?: () => void;
}

export const useMyUser = create<MyUserStore>((set, get) => ({
  isInitializing: true,
  subscribe: (userId) => {
    const unsubscribeFirestore = onSnapshot(
      getUserDocRef(userId),
      async (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;
        set({ user: getUserFromSnapshot(snapshot), isInitializing: false });
      },
      (error: any) => {
        toast.error(error.message);
        set({ user: undefined, isInitializing: false });
      }
    );

    set({ unsubscribeFirestore });
  },
  unsubscribe: () => {
    set({ user: undefined, isInitializing: true });
    get().unsubscribeFirestore?.();
  },
}));
