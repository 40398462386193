import { CssBaseline } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FC } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { AppAuthView } from "./AppAuthView";
import { AppThemeProvider } from "./AppThemeProvider";

export const AppProvider: FC = () => {
  return (
    <AppThemeProvider mode="dark">
      <CssBaseline />
      <BrowserRouter>
        <DndProvider backend={HTML5Backend}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <AppAuthView />
          </LocalizationProvider>
        </DndProvider>
      </BrowserRouter>
      <Toaster />
    </AppThemeProvider>
  );
};
