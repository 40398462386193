import create from "zustand";

export interface EscapeKeyStore {
  closes: (() => void)[];
  onKeyDown: (event: KeyboardEvent) => void;
  mount: (close: () => void) => void;
  unmount: (close: () => void) => void;
  subscribe: () => void;
  unsubscribe: () => void;
}

export const useEscapeKey = create<EscapeKeyStore>((set, get) => ({
  closes: [],
  onKeyDown: (event) => {
    const { closes } = get();

    if (event.key !== "Escape") return;
    if (!closes.length) return;

    const lastClose = closes[closes.length - 1];

    lastClose();
    set({ closes: closes.filter((c) => c !== lastClose) });
  },
  mount: (close) => {
    set({ closes: [...get().closes, close] });
  },
  unmount: (close) => {
    set({ closes: get().closes.filter((c) => c !== close) });
  },
  subscribe: () => {
    document.addEventListener("keydown", get().onKeyDown, false);
  },
  unsubscribe: () => {
    document.removeEventListener("keydown", get().onKeyDown, false);
  },
}));
