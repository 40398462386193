import WifiOffIcon from "@mui/icons-material/WifiOff";
import { Box, Typography } from "@mui/material";
import { FC } from "react";
import Logo from "../../Assets/images/zephyr-logo.svg";

export const AppOfflineView: FC = () => {
  return (
    <Box
      p={4}
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
    >
      <WifiOffIcon sx={{ fontSize: 72 }} />
      <Typography mt={4} variant="h4" textAlign="center">
        There is no internet connection.
      </Typography>
      <Box textAlign="center" mt={4} maxWidth={420}>
        <Typography>
          Please restart the router in the store to resolve this issue yourself.
        </Typography>
        <Typography mt={1.5}>
          Please <strong>DO NOT</strong> contact the Zephyr team or the{" "}
          <i style={{ whiteSpace: "nowrap" }}>#zephyr-support</i> channel.
          Unfortunately we can't help you with this.
        </Typography>
        <Typography mt={1.5}>
          If the problem persists, please contact the IT Helpdesk to raise the
          issue. Please notify the{" "}
          <i style={{ whiteSpace: "nowrap" }}>#sos-customer-service</i> slack
          channel in case they need to cancel any orders.
        </Typography>
      </Box>
      <Box mt={6}>
        <img src={Logo} alt="Zephyr" height={28} />
      </Box>
    </Box>
  );
};
