import { Task, Warehouse } from "@quickcommerceltd/zephyr-types";
import { useAppConfig } from "../../App/hooks/useAppConfig";
import { getDistanceInMiles } from "../../Map/helpers/getDistance";
import { RiderWithCoords } from "../types/RiderWithCoords";
import { isRiderInTransit } from "./isRiderInTransit";

export function getEstimatedReturnTimeInMinutes(
  warehouse: Warehouse,
  rider: RiderWithCoords,
  tasks: Task[]
) {
  const riderPerformance = useAppConfig.getState().appConfig?.riderPerformance;
  const riderCoords = rider.coords;
  let distance = 0;
  let minutes = 0;

  if (!riderCoords) return undefined;
  if (!riderPerformance) return undefined;
  if (rider.isThirdPartyProvider) return undefined;
  if (!isRiderInTransit(rider)) return 0;

  if (rider.status === "RIDING_BACK_TO_STORE") {
    distance += getDistanceInMiles(riderCoords, warehouse.address);
  } else {
    if (rider.status === "IDLE_AT_CUSTOMER") {
      minutes += riderPerformance.avgStartNextTaskTimeInMinutes;
    }

    tasks.forEach((task, index) => {
      if (index === 0) {
        distance += getDistanceInMiles(riderCoords, task.address.location);
      } else {
        distance += getDistanceInMiles(
          tasks[index - 1].address.location,
          task.address.location
        );
      }

      minutes += riderPerformance.avgHandoverTimeInMinutes;
      minutes += riderPerformance.avgStartNextTaskTimeInMinutes;

      if (index === tasks.length - 1) {
        distance += getDistanceInMiles(
          task.address.location,
          warehouse.address
        );
      }
    });
  }

  minutes += (distance / riderPerformance.avgMilesPerHours) * 60;

  return minutes;
}
