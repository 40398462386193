import { User } from "@quickcommerceltd/zephyr-types";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { deleteUserCallable } from "../../Auth/callables/user/deleteUserCallable";
import { logError } from "../../Common/helpers/logError";

export function useDeleteUser(): [(user: User) => Promise<void>, boolean] {
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  const deleteUser = useCallback(async (user: User) => {
    try {
      setIsDeletingUser(true);

      await deleteUserCallable({ userId: user.id });
    } catch (error: any) {
      toast.error(error.message);
      logError(error);
    } finally {
      setIsDeletingUser(false);
    }
  }, []);

  return [deleteUser, isDeletingUser];
}
