import {
  Rider,
  RiderLocationLog,
  RiderLog,
  Task,
  TaskLog,
  User,
  Warehouse,
  WarehouseRiderLocation,
} from "@quickcommerceltd/zephyr-types";
import { collection, CollectionReference } from "firebase/firestore";
import { CollectionName } from "../types/CollectionName";
import { db } from "./firebase";

function getCollectionRef<T>(collectionName: CollectionName) {
  return collection(db, collectionName) as CollectionReference<T>;
}

export function getTaskCollectionRef() {
  return getCollectionRef<Task>("tasks");
}

export function getTaskLogCollectionRef(taskId: string) {
  return getCollectionRef<TaskLog>(`tasks/${taskId}/logs`);
}

export function getRiderCollectionRef() {
  return getCollectionRef<Rider>("riders");
}

export function getRiderLogCollectionRef(riderId: string) {
  return getCollectionRef<RiderLog>(`riders/${riderId}/logs`);
}

export function getRiderLocationLogCollectionRef(riderId: string) {
  return getCollectionRef<RiderLocationLog>(`riderLocations/${riderId}/logs`);
}

export function getUserCollectionRef() {
  return getCollectionRef<User>("users");
}

export function getWarehouseCollectionRef() {
  return getCollectionRef<Warehouse>("warehouses");
}

export function getWarehouseRiderLocationCollectionRef() {
  return getCollectionRef<WarehouseRiderLocation>("warehouseRiderLocations");
}
