import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, Menu, MenuItem } from "@mui/material";
import {
  Rider,
  Task,
  ThirdPartyProvider,
} from "@quickcommerceltd/zephyr-types";
import { FC, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { getThirdPartyProviderTranslation } from "../../Common/helpers/getThirdPartyProviderTranslation";
import { logError } from "../../Common/helpers/logError";
import { useVisibleThirdPartyProviders } from "../../Common/hooks/useVisibleThirdPartyProviders";
import { getRiderName } from "../../Rider/helpers/getRiderName";
import { validateAssignTaskToThirdPartyProvider } from "../../Rider/helpers/validateAssignTaskToThirdPartyProvider";
import { validateUnassignTaskFromRider } from "../../Rider/helpers/validateUnassignTaskFromRider";
import { useAssignableRiders } from "../../Rider/hooks/useAssignableRiders";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { useAssignTask } from "../hooks/useAssignTask";
import { useAssignTaskToThirdPartyProvider } from "../hooks/useAssignTaskToThirdPartyProvider";
import { useUnassignTask } from "../hooks/useUnassignTask";

interface Props {
  task: Task;
  isDisabled?: boolean;
}

export const TaskAssignRiderSelect: FC<Props> = (props) => {
  const warehouse = useSelectedWarehouse();
  const assignableRiders = useAssignableRiders(props.task);
  const thirdPartyProviders = useVisibleThirdPartyProviders();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [assignTask, isAssigningTask] = useAssignTask();
  const [assignTaskToThirdPartyProvider, isAssigningTaskToThirdParyProvider] =
    useAssignTaskToThirdPartyProvider();
  const [unassignTask, isUnassigningTask] = useUnassignTask();

  const enabledThirdPartyProviders = useMemo(() => {
    return thirdPartyProviders.filter(
      (provider) =>
        validateAssignTaskToThirdPartyProvider(provider, props.task, warehouse)
          .isValid
    );
  }, [thirdPartyProviders, warehouse, props.task]);

  const assignToRider = useCallback(
    async (rider?: Rider) => {
      try {
        setAnchorEl(null);

        if (rider) {
          await assignTask(props.task, rider);
        } else {
          await unassignTask(props.task);
        }
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      }
    },
    [assignTask, props.task, unassignTask]
  );

  const assignToThirdPartyProvider = useCallback(
    async (provider: ThirdPartyProvider) => {
      try {
        setAnchorEl(null);
        await assignTaskToThirdPartyProvider(props.task, provider);
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      }
    },
    [assignTaskToThirdPartyProvider, props.task]
  );

  const isUnassignEnabled = validateUnassignTaskFromRider(props.task).isValid;
  const isLoading =
    isAssigningTask ||
    isAssigningTaskToThirdParyProvider ||
    isUnassigningTask ||
    !!props.task.isRunningThirdPartyProviderAction;

  return (
    <>
      <LoadingButton
        data-testid="TaskAssignRiderSelect-Assign-LoadingButton"
        loading={isLoading}
        disabled={props.isDisabled || isLoading}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
      >
        {props.task.riderId ? "Reassign" : "Assign"}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {isUnassignEnabled && (
          <MenuItem onClick={() => assignToRider(undefined)}>
            <em>Unassign</em>
          </MenuItem>
        )}
        {isUnassignEnabled && <Divider />}
        {assignableRiders.length === 0 && (
          <MenuItem onClick={() => setAnchorEl(null)}>
            <em>No assignable 1P riders</em>
          </MenuItem>
        )}
        {assignableRiders.map((r) => (
          <MenuItem key={r.id} onClick={() => assignToRider(r)}>
            {getRiderName(r)}
          </MenuItem>
        ))}
        {!!enabledThirdPartyProviders.length && <Divider />}
        {enabledThirdPartyProviders.map((provider) => (
          <MenuItem
            key={provider}
            onClick={() => assignToThirdPartyProvider(provider)}
          >
            {getThirdPartyProviderTranslation(provider)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
