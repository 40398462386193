import { SxProps, TextField, Theme } from "@mui/material";
import { Control, Controller, FieldPath } from "react-hook-form";

interface Props<T> {
  control: Control<T>;
  name: FieldPath<T>;
  label: string;
  sx?: SxProps<Theme>;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  type?: "number" | "tel";
  multiline?: boolean;
  helperText?: string;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  onPressEnter?: () => void;
}

export function ControlledTextField<T>(props: Props<T>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
        ...(props.type === "tel" && {
          validate: (phone: any) =>
            phone.trim().substring(0, 1) === "+" ||
            "Phone number must start with e.g. +44 or +31",
        }),
      }}
      render={({ field, fieldState }) => (
        <TextField
          size="small"
          {...field}
          placeholder={props.placeholder}
          fullWidth={props.fullWidth}
          required={props.required}
          label={props.label}
          sx={{
            ...props.sx,
            ...(props.endAdornment && {
              ".MuiOutlinedInput-root": { pr: 0 },
            }),
          }}
          {...(props.onPressEnter && {
            onKeyDown: (e) => e.key === "Enter" && props.onPressEnter?.(),
          })}
          type={props.type}
          disabled={props.disabled}
          error={!!fieldState.error}
          helperText={props.helperText || fieldState.error?.message}
          multiline={props.multiline}
          {...(props.type === "number" && {
            onChange: (e) => field.onChange(Number(e.target.value)),
          })}
          InputProps={{ endAdornment: props.endAdornment }}
        />
      )}
    />
  );
}
