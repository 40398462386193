import { Timestamp } from "@quickcommerceltd/zephyr-types";
import { getDiffInSeconds } from "../../Common/helpers/getDiffInSeconds";

/**
 * Get warning priority.
 *
 * 0: none
 * 1: message
 * 2: background
 * 3: flashing
 */
export function getSidebarWarningPriority(
  priorities: number[],
  timestamp: Timestamp
) {
  const index = priorities
    .sort((a, b) => b - a)
    .findIndex((minutes) => getDiffInSeconds(timestamp, true) / 60 >= minutes);

  if (index === -1) return 0;

  return priorities.length - index;
}
