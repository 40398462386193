import { Box } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { FilterMainView } from "../../Filter/components/FilterMainView";
import { MapView } from "../../Map/components/MapView";
import { SidebarView } from "../../Sidebar/components/SidebarView";
import { AppMinVersionView } from "./AppMinVersionView";

export const AppDashboardView: FC = () => {
  return (
    <>
      <Box display="flex">
        <SidebarView />
        <Box display="flex" flexDirection="column" position="relative" flex={1}>
          <MapView />
          <AppMinVersionView />
          <FilterMainView />
        </Box>
      </Box>
      <Outlet />
    </>
  );
};
