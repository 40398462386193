import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Stack,
  Typography,
} from "@mui/material";
import { isTask, Task } from "@quickcommerceltd/zephyr-types";
import { DeliveryOption } from "@quickcommerceltd/zephyr-types/dist/types/DeliveryOption";
import { getDocs, limit, query, where } from "firebase/firestore";
import { FC, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { AppThemeProvider } from "../../App/components/AppThemeProvider";
import { COLOR } from "../../App/constants/COLOR";
import { getTaskCollectionRef } from "../../Auth/helpers/getCollectionRef";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { ControlledAddressSearchField } from "../../Common/components/ControlledAddressSearchField";
import { ControlledSelect } from "../../Common/components/ControlledSelect";
import { ControlledTextField } from "../../Common/components/ControlledTextField";
import { DotView } from "../../Common/components/DotView";
import { Coords } from "../../Common/types/Coords";
import { useRiderFromTask } from "../../Rider/hooks/useRiderFromTask";
import { useMyWarehouses } from "../../Warehouse/hooks/useMyWarehouses";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { getTaskDeliveryOptionTranslation } from "../helpers/getTaskDeliveryOptionTranslation";
import { getTaskStatusTranslation } from "../helpers/getTaskStatusTranslation";
import { useCreateTask } from "../hooks/useCreateTask";
import { useEditTask } from "../hooks/useEditTask";
import { TaskDeleteDialog } from "./TaskDeleteDialog";
import { TaskStatusIcon } from "./TaskStatusIcon";

type TaskFormValues = {
  externalOrderNumber: string;
  customer: {
    firstName: string;
    lastName: string;
    phone: string;
  };
  address: {
    streetAddress1: string;
    postalCode: string;
    city: string;
    country: string;
    deliveryOption: DeliveryOption;
    location: Coords;
  };
  warehouseId: string;
  noteFromCustomer: string;
};

interface Props {
  isOpen: boolean;
  close: () => void;
  task?: Task;
}

export const TaskUpsertDialog: FC<Props> = (props) => {
  const hasPermission = useHasPermission();
  const myWarehouses = useMyWarehouses();
  const selectedWarehouse = useSelectedWarehouse();
  const { createTask, isCreatingTask } = useCreateTask();
  const { editTask, isEditingTask } = useEditTask();
  const [isTaskDeleteDialogOpen, setIsTaskDeleteDialogOpen] = useState(false);
  const [orderNumberHelperText, setOrderNumberHelperText] = useState("");
  const rider = useRiderFromTask(props.task);

  const defaultValues = useMemo(
    () => ({
      externalOrderNumber: "",
      customer: {
        firstName: "",
        lastName: "",
        phone: "",
      },
      address: {
        streetAddress1: "",
        postalCode: "",
        city: "",
        country: "",
        deliveryOption: DeliveryOption.RING_DOOR_BELL,
        location: {
          latitude: 0,
          longitude: 0,
        },
      },
      warehouseId: selectedWarehouse?.id,
      noteFromCustomer: "",
    }),
    [selectedWarehouse?.id]
  );

  const { reset, control, handleSubmit, getValues, setValue } =
    useForm<TaskFormValues>({
      defaultValues: props.task || defaultValues,
    });

  const onSubmit = useCallback(
    async (formValues: TaskFormValues) => {
      if (props.task) {
        await editTask({ taskId: props.task.id, ...formValues });
      } else {
        await createTask(formValues);
      }
      props.close();
    },
    [editTask, createTask, props]
  );

  const closeDialog = useCallback(() => {
    props.close();
    setOrderNumberHelperText("");
    reset(props.task || defaultValues);
  }, [defaultValues, props, reset]);

  const getTaskByOrderNumber = useCallback(async () => {
    const { externalOrderNumber } = getValues();
    const snapshot = await getDocs(
      query(
        getTaskCollectionRef(),
        where("externalOrderNumber", "==", externalOrderNumber),
        limit(1)
      )
    );

    if (!snapshot.empty && isTask(snapshot.docs[0].data())) {
      reset(snapshot.docs[0].data());
      setOrderNumberHelperText("");
    } else {
      reset({ ...defaultValues, externalOrderNumber });
      setOrderNumberHelperText(
        `Task with order number ${externalOrderNumber} was not found!`
      );
    }
  }, [defaultValues, getValues, reset]);

  return (
    <AppThemeProvider mode="light">
      <Dialog
        open={props.isOpen}
        onClose={closeDialog}
        fullWidth
        data-testid="TaskUpsertDialog"
      >
        <form>
          <Box my={2} mx={3} display="flex">
            {!props.task && (
              <DialogTitle sx={{ p: 0, alignSelf: "center" }}>
                Create Task
              </DialogTitle>
            )}
            {props.task && (
              <Box>
                <DialogTitle sx={{ p: 0 }}>Edit Task</DialogTitle>
                <Box display="flex" alignItems="center" color={COLOR.gray[300]}>
                  <TaskStatusIcon
                    task={props.task}
                    riderStatus={rider?.status}
                    size={16}
                  />
                  <Typography mx={1} fontSize={14}>
                    {getTaskStatusTranslation(props.task, rider?.status)}
                  </Typography>
                  <DotView />
                  <Typography ml={1} fontSize={14}>
                    #{props.task.id.substring(props.task.id.length - 8)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Divider />
          <DialogContent>
            <Stack spacing={2}>
              {!props.task && (
                <Box>
                  <Box display="flex" alignItems="baseline">
                    <DialogContentText>Order</DialogContentText>
                    <DialogContentText
                      sx={{ fontSize: 10, color: COLOR.gray[400] }}
                      ml={1}
                    >
                      pre-populate with order number
                    </DialogContentText>
                  </Box>
                  <FormControl sx={{ mt: 2 }} fullWidth>
                    <Box display="flex">
                      <ControlledTextField
                        name="externalOrderNumber"
                        label="Order Number"
                        control={control}
                        fullWidth
                        helperText={orderNumberHelperText}
                        onPressEnter={getTaskByOrderNumber}
                        endAdornment={
                          <Button
                            variant="contained"
                            onClick={getTaskByOrderNumber}
                          >
                            Search
                          </Button>
                        }
                      />
                    </Box>
                  </FormControl>
                </Box>
              )}
              {!props.task?.isAnonymized && (
                <Box>
                  <DialogContentText>Recipient Details</DialogContentText>
                  <FormControl sx={{ mt: 2 }} fullWidth>
                    <Box display="flex">
                      <ControlledTextField
                        name="customer.firstName"
                        label="First name"
                        control={control}
                        sx={{ mr: 2 }}
                      />
                      <ControlledTextField
                        name="customer.lastName"
                        label="Last name"
                        control={control}
                        sx={{ mr: 2 }}
                        required
                      />
                      <ControlledTextField
                        name="customer.phone"
                        label="Phone number"
                        control={control}
                        required
                      />
                    </Box>
                  </FormControl>
                </Box>
              )}
              <Box>
                <DialogContentText>Destination</DialogContentText>
                <FormControl sx={{ mt: 2 }}>
                  <Box display="flex">
                    <ControlledAddressSearchField
                      name="address.streetAddress1"
                      label="Address"
                      control={control}
                      required
                      fullWidth
                      sx={{ mr: 2 }}
                      onAddressChanged={(address) =>
                        setValue("address", {
                          ...address,
                          deliveryOption: getValues().address.deliveryOption,
                        })
                      }
                    />
                    <ControlledTextField
                      name="address.location.latitude"
                      label="Latitude"
                      type="number"
                      control={control}
                      sx={{ mr: 2 }}
                      required
                    />
                    <ControlledTextField
                      name="address.location.longitude"
                      label="Longitude"
                      type="number"
                      control={control}
                      required
                    />
                  </Box>
                  <Box display="flex" mt={2}>
                    <ControlledTextField
                      name="address.postalCode"
                      label="Postal Code"
                      control={control}
                      sx={{ mr: 2 }}
                      required
                    />
                    <ControlledTextField
                      name="address.city"
                      label="City"
                      control={control}
                      sx={{ mr: 2 }}
                      required
                    />
                    <ControlledTextField
                      name="address.country"
                      label="Country"
                      control={control}
                      required
                    />
                  </Box>
                </FormControl>
                {!props.task && (
                  <ControlledSelect
                    control={control}
                    name="warehouseId"
                    label="Select Stores"
                    helperText="At least one store is required"
                    items={myWarehouses}
                    getValue={(warehouse) => warehouse.id}
                    getName={(warehouse) => warehouse.name}
                    sx={{ mt: 2 }}
                    fullWidth
                    required
                  />
                )}
              </Box>
              <Box>
                <DialogContentText>Delivery Notes</DialogContentText>
                <ControlledSelect
                  control={control}
                  name="address.deliveryOption"
                  label="Delivery Instructions"
                  items={Object.values(DeliveryOption)}
                  getValue={(option) => option}
                  getName={(option) => getTaskDeliveryOptionTranslation(option)}
                  sx={{ mt: 2 }}
                  fullWidth
                />
                <ControlledTextField
                  name="noteFromCustomer"
                  label="Rider Notes"
                  control={control}
                  sx={{ mt: 2 }}
                  fullWidth
                  multiline
                />
              </Box>
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <LoadingButton
              variant="contained"
              loading={isEditingTask || isCreatingTask}
              data-testid="UpsertTaskSubmitButton"
              onClick={handleSubmit(onSubmit)}
            >
              {props.task ? "Save task" : "Create task"}
            </LoadingButton>
            <Button data-testid="UpsertTaskCancelButton" onClick={closeDialog}>
              Cancel
            </Button>
            <Box display="flex" flex={1} />
            {props.task &&
              hasPermission("DELETE_TASK") &&
              !props.task.thirdPartyProvider && (
                <>
                  <Button
                    color="error"
                    onClick={() => setIsTaskDeleteDialogOpen(true)}
                    data-testid="UpsertTaskDeleteButton"
                  >
                    Delete
                  </Button>
                  <TaskDeleteDialog
                    task={props.task}
                    isOpen={isTaskDeleteDialogOpen}
                    close={() => setIsTaskDeleteDialogOpen(false)}
                  />
                </>
              )}
          </DialogActions>
        </form>
      </Dialog>
    </AppThemeProvider>
  );
};
