import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { Task, TaskFailureReason } from "@quickcommerceltd/zephyr-types";
import { FC, useCallback } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AppThemeProvider } from "../../App/components/AppThemeProvider";
import { ControlledSelect } from "../../Common/components/ControlledSelect";
import { TASK_FAILURE_REASONS } from "../constants/TASK_FAILURE_REASONS";
import { getTaskFailureReasonTranslation } from "../helpers/getTaskFailureReasonTranslation";
import { useCompleteTask } from "../hooks/useCompleteTask";

interface Props {
  task: Task;
  isOpen: boolean;
  close: () => void;
}

interface FieldValues {
  isSuccessful: boolean;
  failureNotes: string;
  failureReason: TaskFailureReason;
}

export const TaskCompleteDialog: FC<Props> = (props) => {
  const { completeTask, isCompletingTask } = useCompleteTask();
  const { control, getValues, watch, handleSubmit } = useForm<FieldValues>({
    defaultValues: {
      isSuccessful: true,
      failureReason: "NONE",
      failureNotes: "",
    },
  });

  const onSubmit = useCallback<SubmitHandler<FieldValues>>(
    async (values) => {
      await completeTask({
        task: props.task,
        isSuccessful: values.isSuccessful,
        failureReason: values.isSuccessful ? undefined : values.failureReason,
        failureNotes: values.isSuccessful ? undefined : values.failureNotes,
      });

      props.close();
    },
    [completeTask, props]
  );
  const values = getValues();

  watch("isSuccessful");

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>Complete Task</DialogTitle>
        <DialogContent>
          <Controller
            name="isSuccessful"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label={field.value ? "Task Succeeded" : "Task Failed"}
                control={
                  <Switch
                    {...field}
                    color="success"
                    checked={field.value}
                    data-testId="TaskSuccessfulToggleSwitch"
                  />
                }
              />
            )}
          />
          <ControlledSelect
            control={control}
            name="failureReason"
            label="Failure Reason"
            disabled={values.isSuccessful}
            items={TASK_FAILURE_REASONS}
            getValue={(reason) => reason}
            getName={(reason) => getTaskFailureReasonTranslation(reason)}
            sx={{ mt: 2 }}
            fullWidth
          />
          <Controller
            name="failureNotes"
            control={control}
            render={({ field }) => (
              <Box mt={2}>
                <TextField
                  {...field}
                  disabled={values.isSuccessful}
                  label="Failure Notes"
                  rows={3}
                  fullWidth
                  multiline
                  data-testId="TaskCompleteDialogFailureNotes"
                />
              </Box>
            )}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isCompletingTask}
            onClick={handleSubmit(onSubmit)}
            data-testId="TaskCompleteDialogCompleteButton"
          >
            Complete
          </LoadingButton>
          <Button
            onClick={props.close}
            autoFocus
            data-testid="TaskCompleteDialogCancelButton"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  );
};
