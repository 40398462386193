import { useCallback } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { DEFAULT_FEATURE_FLAGS } from "../constants/DEFAULT_FEATURE_FLAGS";
import { FeatureFlag } from "../types/FeatureFlag";

export function useIsFeatureEnabled() {
  const data = useLocalStorage((state) => state.data);
  const hasPermission = useHasPermission();

  return useCallback(
    (featureFlag: FeatureFlag) => {
      const isEnabled = data.featureFlags?.[featureFlag];

      if (hasPermission("UPDATE_FEATURE_FLAGS") && isEnabled !== undefined) {
        return isEnabled;
      }

      return DEFAULT_FEATURE_FLAGS[featureFlag];
    },
    [data.featureFlags, hasPermission]
  );
}
