import { Rider, Task } from "@quickcommerceltd/zephyr-types";
import { getCsvDate } from "../../Common/helpers/getCsvDate";
import { getCsvString } from "../../Common/helpers/getCsvString";
import { CsvRow } from "../../Common/types/CsvRow";
import { getRiderName } from "../../Rider/helpers/getRiderName";
import { getTaskCloneReasonTranslation } from "./getTaskCloneReasonTranslation";
import { getTaskFailureReasonTranslation } from "./getTaskFailureReasonTranslation";
import { getTaskStatusTranslation } from "./getTaskStatusTranslation";

export function getTaskCsvRows(tasks: Task[], allRiders: Rider[]): CsvRow[] {
  return tasks.reduce<CsvRow[]>(
    (result, task) => {
      const rider = allRiders.find((r) => r.id === task.riderId);

      result.push([
        task.id,
        getCsvString(task.externalOrderId),
        getCsvString(task.externalOrderNumber),
        getTaskStatusTranslation(task),
        rider && getCsvString(getRiderName(rider)),
        getCsvString(task.address.streetAddress1),
        getCsvString(task.address.postalCode),
        `"${task.address.location.latitude},${task.address.location.longitude}"`,
        task.failureReason &&
          getTaskFailureReasonTranslation(task.failureReason),
        task.cloneReason && getTaskCloneReasonTranslation(task.cloneReason),
        getCsvDate(task.createdAt),
        getCsvDate(task.assignedAt),
        getCsvDate(task.startedAt),
        getCsvDate(task.arrivedAtCustomerAt),
        getCsvDate(task.completedAt),
        getCsvDate(task.arrivedAtStoreAt),
      ]);

      return result;
    },
    [
      [
        "task_id",
        "order_id",
        "order_number",
        "task_status",
        "rider_name",
        "destination_address",
        "destination_postal_code",
        "destination_coords",
        "customer_name",
        "failure_reason",
        "task_created_at",
        "task_assigned_at",
        "task_started_at",
        "rider_arrived_at_customer_at",
        "task_completed_at",
        "rider_arrived_at_store_at",
      ],
    ]
  );
}
