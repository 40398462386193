import { createTheme } from "@mui/material";
import { COLOR } from "./COLOR";

export const THEME_DARK_MODE = createTheme({
  palette: {
    mode: "dark",
    text: {
      primary: COLOR.white[0],
    },
    background: {
      default: COLOR.rock[500],
      paper: COLOR.rock[500],
    },
    primary: {
      main: COLOR.blue[500],
      light: COLOR.blue[900],
    },
    secondary: {
      main: COLOR.blue[900],
    },
    divider: COLOR.rock[400],
  },
  typography: {
    body1: {
      fontSize: 14,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "unset",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.rock[300],
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.blue[50],
          color: COLOR.blue[900],
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 6,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          "& > .MuiDivider-root": {
            borderColor: COLOR.gray[500],
          },
        },
      },
    },
  },
});
