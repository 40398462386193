import { Rider, WarehouseRiderLocation } from "@quickcommerceltd/zephyr-types";
import { RiderWithCoords } from "../types/RiderWithCoords";

export function getRidersWithCoords(
  riders: Rider[],
  riderLocations: WarehouseRiderLocation["riderLocations"]
) {
  return riders.reduce<RiderWithCoords[]>((result, rider) => {
    const riderLocation = riderLocations.find((rl) => rl.riderId === rider.id);

    result.push({
      ...rider,
      ...(riderLocation && {
        coords: {
          latitude: riderLocation.latitude,
          longitude: riderLocation.longitude,
        },
        estimatedArrivalAtStoreAt: riderLocation.estimatedArrivalAtStoreAt,
      }),
    });
    return result;
  }, []);
}
