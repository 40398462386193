import { Box, List, ListItem, Typography } from "@mui/material";
import { FC } from "react";
import { COLOR } from "../../App/constants/COLOR";
import { DotView } from "../../Common/components/DotView";
import { useFilter } from "../../Filter/hooks/useFilter";
import { isRiderInTransit } from "../../Rider/helpers/isRiderInTransit";
import { isRiderNotStarted } from "../../Rider/helpers/isRiderNotStarted";
import { useRiders } from "../../Rider/hooks/useRiders";
import { WarehouseStatusIcon } from "../../Warehouse/components/WarehouseStatusIcon";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";

export const SidebarWarehouseSelect: FC = () => {
  const filter = useFilter();
  const { riders } = useRiders();
  const selectedWarehouse = useSelectedWarehouse();

  return (
    <List>
      <ListItem
        button
        data-testid="SidebarSelectWarehouseButton"
        onClick={() =>
          filter.setFilterKind(
            filter.filterKind === "warehouse" ? undefined : "warehouse"
          )
        }
      >
        <Box>
          <Box display="flex" alignItems="center">
            <Typography fontWeight={700} fontSize={16}>
              {selectedWarehouse?.name || "Select store"}
            </Typography>
            {selectedWarehouse && (
              <>
                <Box ml={1}>
                  <WarehouseStatusIcon
                    warehouseStatus={selectedWarehouse.status}
                  />
                </Box>
                <Typography fontWeight={500} fontSize={12} sx={{ ml: 1 }}>
                  Delivery Promise: {selectedWarehouse.deliveryTimeInMinutes}{" "}
                  Minutes
                </Typography>
              </>
            )}
          </Box>
          <Typography fontWeight={50} fontSize={12}>
            {riders.filter((r) => r.status !== "OFF_DUTY").length} Online
            <Box component="span" mx={0.2} color={COLOR.green[500]}>
              <DotView />
            </Box>
            {riders.filter(isRiderNotStarted).length} At Store
            <Box component="span" mx={0.2} color={COLOR.blue[500]}>
              <DotView />
            </Box>
            {riders.filter(isRiderInTransit).length} In Transit
          </Typography>
        </Box>
      </ListItem>
    </List>
  );
};
