import { Box } from "@mui/material";
import { FC } from "react";
import { APP_HEADER_HEIGHT } from "../../App/constants/APP_HEADER_HEIGHT";
import { SIDEBAR_WIDTH } from "../constants/SIDEBAR_WIDTH";
import { useSidebarSearch } from "../hooks/useSidebarSearch";
import { SidebarCompletedView } from "./SidebarCompletedView";
import { SidebarInTransitView } from "./SidebarInTransitView";
import { SidebarSearchResults } from "./SidebarSearchResults";
import { SidebarSearchTextField } from "./SidebarSearchTextField";
import { SidebarNotStartedView } from "./SidebarNotStartedView";
import { SidebarWarehouseSelect } from "./SidebarWarehouseSelect";

export const SidebarView: FC = () => {
  const isSearching = useSidebarSearch((state) => !!state.query);

  return (
    <Box
      width={SIDEBAR_WIDTH}
      overflow="auto"
      height={`calc(100vh - ${APP_HEADER_HEIGHT}px)`}
      sx={{ boxShadow: 3 }}
    >
      <SidebarSearchTextField />
      {isSearching && <SidebarSearchResults />}
      {!isSearching && (
        <>
          <SidebarWarehouseSelect />
          <SidebarNotStartedView />
          <SidebarInTransitView />
          <SidebarCompletedView />
        </>
      )}
    </Box>
  );
};
