import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { ENVIRONMENT } from "../constants/ENVIRONMENT";

export function useIsOffline() {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  const checkConnection = useCallback(() => {
    if (ENVIRONMENT.nodeEnv === "development") return;

    axios(`/no-cache.png?v=${Date.now()}`, { timeout: 9500 })
      .then(() => setIsOffline(false))
      .catch(() => setIsOffline(true));
  }, []);

  useEffect(() => {
    const interval = setInterval(checkConnection, 10000);
    const onOffline = () => setIsOffline(true);
    const onOnline = () => {
      if (ENVIRONMENT.nodeEnv === "development") {
        setIsOffline(false);
      } else {
        checkConnection();
      }
    };

    checkConnection();
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);

    return () => {
      clearInterval(interval);
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
  }, [checkConnection]);

  return isOffline;
}
