import { SidebarWarning } from "../types/SidebarWarning";

export function getSidebarWarningTranslation(sidebarWarning: SidebarWarning) {
  switch (sidebarWarning.type) {
    case "NOT_ASSIGNED":
      return `Unassigned for more than {diff}`;
    case "NOT_ASSIGNED_WHEN_PENDING":
      return `Pending for more than {diff}`;
    case "NOT_STARTED":
      return `Not started for more than {diff}`;
    default:
      return sidebarWarning.type;
  }
}
