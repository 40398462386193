import {
  AppConfig,
  isAppConfig,
  isRider,
  isTask,
  isUser,
  isWarehouseRiderLocation,
  Rider,
  Task,
  User,
  WarehouseRiderLocation,
} from "@quickcommerceltd/zephyr-types";
import { DocumentSnapshot } from "firebase/firestore";

export function getDataFromDocumentSnapshot<T>(
  isT: (obj: unknown) => obj is T,
  doc?: DocumentSnapshot<T>
) {
  if (doc?.exists()) {
    const data = doc.data();

    if (isT(data)) {
      return data;
    }
  }
}

export function getAppConfigFromSnapshot(doc?: DocumentSnapshot<AppConfig>) {
  return getDataFromDocumentSnapshot(isAppConfig, doc);
}

export function getRiderFromSnapshot(doc?: DocumentSnapshot<Rider>) {
  return getDataFromDocumentSnapshot(isRider, doc);
}

export function getTaskFromSnapshot(doc?: DocumentSnapshot<Task>) {
  return getDataFromDocumentSnapshot(isTask, doc);
}

export function getUserFromSnapshot(doc?: DocumentSnapshot<User>) {
  return getDataFromDocumentSnapshot(isUser, doc);
}

export function getWarehouseRiderLocationFromSnapshot(
  doc?: DocumentSnapshot<WarehouseRiderLocation>
) {
  return getDataFromDocumentSnapshot(isWarehouseRiderLocation, doc);
}
