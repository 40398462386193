import { useMemo } from "react";
import { useTasks } from "./useTasks";

export function useTasksByRiderId(riderId?: string) {
  const tasks = useTasks((store) => store.tasks);

  return useMemo(
    () =>
      tasks
        .filter(
          (t) =>
            riderId &&
            t.riderId === riderId &&
            ["ASSIGNED", "DELIVERING"].includes(t.status)
        )
        .sort(
          (a, b) =>
            (a.assignedAt?.toMillis() || 0) - (b.assignedAt?.toMillis() || 0)
        ),
    [riderId, tasks]
  );
}
