import { TaskLog } from "@quickcommerceltd/zephyr-types";
import { FirestoreError } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { getTaskLogCollectionRef } from "../../Auth/helpers/getCollectionRef";
import { getTaskLogsFromSnapshot } from "../../Auth/helpers/getDataFromQuerySnapshot";

export function useTaskLogs(
  taskId?: string
): [TaskLog[] | undefined, boolean, FirestoreError | undefined] {
  const [snapshot, isLoading, error] = useCollection(
    getTaskLogCollectionRef(taskId || "unknown")
  );

  const taskLogs = getTaskLogsFromSnapshot(snapshot);

  taskLogs?.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());

  return [taskLogs, isLoading, error];
}
