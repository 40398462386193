import { ThemeProvider } from "@emotion/react";
import { FC, ReactNode } from "react";
import { THEME_DARK_MODE } from "../constants/THEME_DARK_MODE";
import { THEME_LIGHT_MODE } from "../constants/THEME_LIGHT_MODE";

interface Props {
  children: ReactNode;
  mode: "dark" | "light";
}

export const AppThemeProvider: FC<Props> = (props) => {
  return (
    <ThemeProvider
      theme={props.mode === "dark" ? THEME_DARK_MODE : THEME_LIGHT_MODE}
    >
      {props.children}
    </ThemeProvider>
  );
};
