import { Warehouse } from "@quickcommerceltd/zephyr-types";
import { onSnapshot } from "firebase/firestore";
import toast from "react-hot-toast";
import create from "zustand";
import { getWarehouseCollectionRef } from "../../Auth/helpers/getCollectionRef";
import { getWarehousesFromSnapshot } from "../../Auth/helpers/getDataFromQuerySnapshot";

export interface WarehousesStore {
  warehouses: Warehouse[];
  isInitializing: boolean;
  subscribe: () => void;
  unsubscribe: () => void;
  unsubscribeFirestore?: () => void;
}

export const useWarehouses = create<WarehousesStore>((set, get) => ({
  warehouses: [],
  isInitializing: true,
  subscribe: () => {
    const unsubscribeFirestore = onSnapshot(
      getWarehouseCollectionRef(),
      async (snapshot) => {
        const warehouses = getWarehousesFromSnapshot(snapshot)
          .filter((warehouse) => !warehouse.isDistributionCenter)
          .sort((a, b) => a.name.localeCompare(b.name));

        set({
          warehouses,
          isInitializing: false,
        });
      },
      (error: any) => {
        toast.error(error.message);
        set({ warehouses: [], isInitializing: false });
      }
    );

    set({ unsubscribeFirestore });
  },
  unsubscribe: () => {
    set({ warehouses: [] });
    get().unsubscribeFirestore?.();
  },
}));
