import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { User } from "@quickcommerceltd/zephyr-types";
import { FC } from "react";
import { AppThemeProvider } from "../../App/components/AppThemeProvider";
import { useDeleteUser } from "../hooks/useDeleteUser";

interface Props {
  user?: User;
  isOpen: boolean;
  close: () => void;
}

export const SettingsUserDeleteDialog: FC<Props> = (props) => {
  const [deleteUser, isDeletingUser] = useDeleteUser();

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>Delete User</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the user{" "}
            <strong>{props.user?.name}</strong>?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton
            color="error"
            variant="contained"
            loading={isDeletingUser}
            onClick={async () => {
              if (!props.user) return;
              await deleteUser(props.user);
              props.close();
            }}
          >
            Delete
          </LoadingButton>
          <Button onClick={props.close} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  );
};
