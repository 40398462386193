import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import {
  Box,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
} from "@mui/material";
import { Task } from "@quickcommerceltd/zephyr-types";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { COLOR } from "../../App/constants/COLOR";
import { getTaskDocRef } from "../../Auth/helpers/getDocRef";
import { FragmentWithDots } from "../../Common/components/FragmentWithDots";
import { RelativeTimestampView } from "../../Common/components/RelativeTimestampView";
import { logError } from "../../Common/helpers/logError";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { useMap } from "../../Map/hooks/useMap";
import { getRiderName } from "../../Rider/helpers/getRiderName";
import { useRiderFromTask } from "../../Rider/hooks/useRiderFromTask";
import { TaskPickingStatusIcon } from "../../Task/components/TaskPickingStatusIcon";
import { TaskStatusIcon } from "../../Task/components/TaskStatusIcon";
import { TASK_COMPLETE_STATUSES } from "../../Task/constants/TASK_COMPLETE_STATUSES";
import { getTaskPickingStatusTranslation } from "../../Task/helpers/getTaskPickingStatusTranslation";
import { getTaskStatusTranslation } from "../../Task/helpers/getTaskStatusTranslation";
import { hasTaskLeftTheStore } from "../../Task/helpers/hasTaskLeftTheStore";
import { useDragTask } from "../../Task/hooks/useDragTask";
import { useTaskSidebarWarning } from "../../Task/hooks/useTaskSidebarWarning";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { useWarehouseFromWarehouseId } from "../../Warehouse/hooks/useWarehouseFromWarehouseId";
import { getSidebarWarningTranslation } from "../helpers/getSidebarWarningTranslation";
import { SidebarListItem } from "./SidebarListItem";

interface Props {
  task: Task;
  isMoveToBottomAllowed?: boolean;
  pl?: number;
}

export const SidebarTaskListItem: FC<Props> = (props) => {
  const params = useParams<"riderId">();
  const selectedWarehouse = useSelectedWarehouse();
  const rider = useRiderFromTask(props.task);
  const warehouse = useWarehouseFromWarehouseId(props.task.warehouseId);
  const localStorage = useLocalStorage();
  const [{ isDragging, isLoading }, drag] = useDragTask(props.task);
  const [isMovingToBottom, setIsMovingToBottom] = useState(false);
  const setFocusedRiderId = useMap((store) => store.setFocusedRiderId);
  const setFocusedTaskIds = useMap((store) => store.setFocusedTaskIds);
  const removeFocusedTaskIds = useMap((store) => store.removeFocusedTaskIds);
  const removeFocusedRiderId = useMap((store) => store.removeFocusedRiderId);
  const isMapTaskFocused = useMap((store) =>
    store.focusedMapTaskId
      ? store.focusedMapTaskId === props.task.id
      : undefined
  );
  const unmountRef = useRef<() => void>();
  const sidebarWarning = useTaskSidebarWarning(props.task, rider);

  const moveToBottom = useCallback(async () => {
    try {
      setIsMovingToBottom(true);

      // TODO: Replace with `assignTaskCallable` as soon as unassign/assign is transaction save!
      // Right now it is flickering when moving to bottom.
      await updateDoc(getTaskDocRef(props.task.id), {
        assignedAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    } catch (error: any) {
      toast.error(error.message);
      logError(error);
    } finally {
      setIsMovingToBottom(false);
    }
  }, [props.task.id]);

  useEffect(() => {
    return () => {
      unmountRef.current?.();
      unmountRef.current = undefined;
    };
  }, []);

  return (
    <SidebarListItem
      ref={drag}
      sx={{ opacity: isMapTaskFocused === false ? 0.1 : isDragging ? 0.4 : 1 }}
      className={`warning-priority-${sidebarWarning?.priority || 0}`}
      to={`${params.riderId ? `/riders/${params.riderId}` : ""}/tasks/${
        props.task.id
      }`}
      onMouseOver={() => {
        setFocusedTaskIds([props.task.id]);
        setFocusedRiderId(
          ["ASSIGNED", "DELIVERING"].includes(props.task.status) &&
            props.task.riderId
            ? props.task.riderId
            : "NONE"
        );
        unmountRef.current = () => {
          removeFocusedTaskIds();
          removeFocusedRiderId();
        };
      }}
      onMouseOut={() => {
        unmountRef.current?.();
        unmountRef.current = undefined;
      }}
      data-testid={`SidebarTaskListItem-${props.task.id}`}
    >
      <Box pl={props.pl}>
        <Tooltip
          placement="right"
          title={
            props.task.hasPriority &&
            ["UNASSIGNED", "PENDING_RIDER_ASSIGNMENT", "ASSIGNED"].includes(
              props.task.status
            )
              ? "Task with high priority. Deliver as soon as possible!"
              : getTaskStatusTranslation(props.task, rider?.status)
          }
        >
          <IconButton sx={{ p: 0 }}>
            <TaskStatusIcon
              isLoading={isLoading || isMovingToBottom}
              task={props.task}
              riderStatus={rider?.status}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Box ml={1}>
        <Typography fontSize={12} fontWeight={700}>
          {!hasTaskLeftTheStore(props.task.status, rider?.status) && (
            <Tooltip
              placement="right"
              title={getTaskPickingStatusTranslation(props.task.pickingStatus)}
            >
              <IconButton sx={{ p: 0, mr: 0.75 }}>
                <TaskPickingStatusIcon
                  isLoading={isLoading}
                  pickingStatus={props.task.pickingStatus}
                />
              </IconButton>
            </Tooltip>
          )}
          <FragmentWithDots
            items={[
              props.task.address.streetAddress1,
              rider &&
                TASK_COMPLETE_STATUSES.includes(props.task.status) &&
                getRiderName(rider),
            ]}
          />
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={500}
          sx={{ opacity: 0.75, color: sidebarWarning && COLOR.orange[500] }}
        >
          {sidebarWarning && (
            <FragmentWithDots
              items={[
                props.task.externalOrderNumber,
                <RelativeTimestampView
                  timestamp={sidebarWarning.timestamp}
                  translation={getSidebarWarningTranslation(sidebarWarning)}
                  shouldBeInThePast
                />,
              ]}
            />
          )}
          {!sidebarWarning && (
            <FragmentWithDots
              items={[
                props.task.externalOrderNumber,
                props.task.status !== "UNASSIGNED" &&
                  getTaskStatusTranslation(props.task, rider?.status),
                <RelativeTimestampView
                  timestamp={props.task.createdAt}
                  translation="Created {diff} ago"
                  shouldBeInThePast
                />,
              ]}
            />
          )}
        </Typography>
      </Box>
      <ListItemSecondaryAction>
        {props.isMoveToBottomAllowed && (
          <Tooltip title="Move to bottom" placement="right">
            <IconButton disabled={isMovingToBottom} onClick={moveToBottom}>
              <ArrowCircleDownIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        )}
        {warehouse && selectedWarehouse?.id !== warehouse.id && (
          <Tooltip title={`Switch to ${warehouse.name}`} placement="right">
            <IconButton
              onClick={() =>
                localStorage.set({ selectedWarehouseId: warehouse.id })
              }
            >
              <MyLocationOutlinedIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        )}
      </ListItemSecondaryAction>
    </SidebarListItem>
  );
};
