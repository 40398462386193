import { Rider } from "@quickcommerceltd/zephyr-types";
import { FirestoreError, query, where } from "firebase/firestore";
import { useMemo, useRef } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { getRiderCollectionRef } from "../../Auth/helpers/getCollectionRef";
import { getRidersFromSnapshot } from "../../Auth/helpers/getDataFromQuerySnapshot";
import { getRiderName } from "../helpers/getRiderName";

export function useAllRiders(): [Rider[], boolean, FirestoreError | undefined] {
  const ridersRef = useRef<Rider[]>([]);
  const [snapshot, isLoading, error] = useCollection(
    query(getRiderCollectionRef(), where("isThirdPartyProvider", "==", false))
  );

  useMemo(() => {
    if (snapshot?.metadata.hasPendingWrites) return;
    ridersRef.current = getRidersFromSnapshot(snapshot).sort((a, b) =>
      getRiderName(a).localeCompare(getRiderName(b))
    );
  }, [snapshot]);

  return [ridersRef.current, isLoading, error];
}
