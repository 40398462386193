import { useDrop } from "react-dnd";
import { validateUnassignTaskFromRider } from "../../Rider/helpers/validateUnassignTaskFromRider";
import { DragItem } from "../../Sidebar/types/DragItem";
import { DropCollect } from "../../Sidebar/types/DropCollect";
import { DropResult } from "../../Sidebar/types/DropResult";

export function useDropToUnassignTask() {
  return useDrop<DragItem, DropResult, DropCollect>(
    () => ({
      accept: ["ASSIGNED_TASK"],
      drop: (_, monitor) => ({
        isTaskUnassign: true,
        error: validateUnassignTaskFromRider(monitor.getItem()).error,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        error: validateUnassignTaskFromRider(monitor.getItem()).error,
      }),
    }),
    []
  );
}
