import { FC } from "react";
import { FragmentWithDots } from "../../Common/components/FragmentWithDots";
import { RelativeTimestampView } from "../../Common/components/RelativeTimestampView";
import { useTasksByRiderId } from "../../Task/hooks/useTasksByRiderId";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { getEstimatedReturnTimeTranslation } from "../helpers/getEstimatedReturnTimeTranslation";
import { getRiderStatusTranslation } from "../helpers/getRiderStatusTranslation";
import { RiderWithCoords } from "../types/RiderWithCoords";

interface Props {
  rider: RiderWithCoords;
}

export const RiderDescriptionView: FC<Props> = (props) => {
  const selectedWarehouse = useSelectedWarehouse();
  const tasks = useTasksByRiderId(props.rider.id);
  const isArrivedAtStoreVisible = [
    "IDLE_AT_STORE",
    "WAITING_FOR_PACKED_ORDER",
  ].includes(props.rider.status);
  const isEstimatedArrivalAtStoreVisible = props.rider.status === "PICKING_UP";

  return (
    <FragmentWithDots
      items={[
        getRiderStatusTranslation(props.rider.status),
        isArrivedAtStoreVisible && props.rider.arrivedAtStoreAt && (
          <RelativeTimestampView
            timestamp={props.rider.arrivedAtStoreAt}
            translation="Arrived {diff} ago"
            shouldBeInThePast
          />
        ),
        selectedWarehouse &&
          getEstimatedReturnTimeTranslation(
            selectedWarehouse,
            props.rider,
            tasks
          ),
        isEstimatedArrivalAtStoreVisible &&
          props.rider.estimatedArrivalAtStoreAt && (
            <RelativeTimestampView
              timestamp={props.rider.estimatedArrivalAtStoreAt}
              translation="Arrives in {diff}"
              shouldBeInThePast={false}
            />
          ),
      ]}
    />
  );
};
