import { RiderStatus, TaskStatus } from "@quickcommerceltd/zephyr-types";
import { getTaskStatusMarkerUrl } from "../../Task/helpers/getTaskStatusMarkerUrl";

export function getTaskMarkerIcon(
  isFocused: boolean,
  taskStatus: TaskStatus,
  riderStatus?: RiderStatus
): google.maps.Icon {
  const size = isFocused ? 38 : 32;

  return {
    url: getTaskStatusMarkerUrl(taskStatus, riderStatus),
    scaledSize: new google.maps.Size(size, size),
  };
}
