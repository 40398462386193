import { RiderLog } from "@quickcommerceltd/zephyr-types";
import { FirestoreError, limit, orderBy, query } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { getRiderLogCollectionRef } from "../../Auth/helpers/getCollectionRef";
import { getRiderLogsFromSnapshot } from "../../Auth/helpers/getDataFromQuerySnapshot";

export function useRiderLogs(
  riderId?: string
): [RiderLog[] | undefined, boolean, FirestoreError | undefined] {
  const [snapshot, isLoading, error] = useCollection(
    query(
      getRiderLogCollectionRef(riderId || "unknown"),
      orderBy("createdAt", "desc"),
      limit(20)
    )
  );

  const riderLogs = getRiderLogsFromSnapshot(snapshot);

  riderLogs?.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());

  return [riderLogs, isLoading, error];
}
