import {
  Task,
  ThirdPartyProvider,
  Warehouse,
} from "@quickcommerceltd/zephyr-types";
import { getThirdPartyProviderTranslation } from "../../Common/helpers/getThirdPartyProviderTranslation";
import { validateUnassignTaskFromRider } from "./validateUnassignTaskFromRider";

interface Validation {
  isValid: boolean;
  error?: Error;
}

export function validateAssignTaskToThirdPartyProvider(
  thirdPartyProvider: ThirdPartyProvider,
  task?: Task | null,
  warehouse?: Warehouse
): Validation {
  try {
    if (!warehouse) {
      throw Error("No store selected.");
    }

    if (!task) {
      throw Error("Missing task.");
    }

    if (task.thirdPartyProvider === thirdPartyProvider) {
      throw Error(
        `Task is already assigned to ${getThirdPartyProviderTranslation(
          thirdPartyProvider
        )}.`
      );
    }

    if (!warehouse.thirdPartyProviders.includes(thirdPartyProvider)) {
      throw Error(
        `${getThirdPartyProviderTranslation(
          thirdPartyProvider
        )} is disabled in ${warehouse.name}.`
      );
    }

    if (task.riderId) {
      const { error } = validateUnassignTaskFromRider(task);

      if (error) {
        throw error;
      }
    }

    return { isValid: true };
  } catch (error: any) {
    return { isValid: false, error };
  }
}
