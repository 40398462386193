import { RelativeTimestampView } from "../../Common/components/RelativeTimestampView";
import { Log } from "../types/Log";

interface Props {
  log: Log;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  onClick?: () => void;
}

export function getLogListItemProps(props: Props) {
  return {
    createdAt: props.log.createdAt,
    createdByName: props.log.updatedBy.name,
    message: (
      <RelativeTimestampView
        timestamp={props.log.createdAt}
        translation={`{diff} ago by ${props.log.updatedBy.name}`}
        shouldBeInThePast
      />
    ),
    isFirstItem: props.isFirstItem,
    isLastItem: props.isLastItem,
    onClick: props.onClick,
  };
}
