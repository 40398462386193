import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { RiderStatus } from "@quickcommerceltd/zephyr-types";
import { FC } from "react";
import { COLOR } from "../../App/constants/COLOR";
import { LoadingIcon } from "../../Common/components/LoadingIcon";

const DEFAULT_ICON_SIZE = 20;

interface Props {
  riderStatus: RiderStatus;
  size?: number;
  isLoading?: boolean;
}

export const RiderStatusIcon: FC<Props> = (props) => {
  const fontSize = props.size || DEFAULT_ICON_SIZE;

  if (props.isLoading) {
    return <LoadingIcon size={fontSize} />;
  }

  switch (props.riderStatus) {
    case "OFF_DUTY":
      return <CircleOutlinedIcon sx={{ color: COLOR.blue[300], fontSize }} />;
    case "IDLE_AT_STORE":
      return <SquareRoundedIcon sx={{ color: COLOR.green[500], fontSize }} />;
    case "DELIVERING":
    case "IDLE_AT_CUSTOMER":
      return <ArrowCircleRightIcon sx={{ color: COLOR.blue[500], fontSize }} />;
    case "WAITING_FOR_PACKED_ORDER":
      return <CircleIcon sx={{ color: COLOR.blue[500], fontSize }} />;
    case "RIDING_BACK_TO_STORE":
    case "PICKING_UP":
      return (
        <ArrowCircleLeftIcon sx={{ color: COLOR.violet[500], fontSize }} />
      );
  }
};
