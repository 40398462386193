import { TaskFailureReason } from "@quickcommerceltd/zephyr-types";

export function getTaskFailureReasonTranslation(reason: TaskFailureReason) {
  switch (reason) {
    case "ORDER_CANCELLED":
      return "Order Cancelled";
    case "DELIVERY_INCIDENT":
      return "Driver / vehicle incident";
    case "DESTINATION_INCORRECT":
      return "Destination unreachable";
    case "ITEM_DAMAGED":
      return "Refused - damage";
    case "ITEM_INCORRECT":
      return "Refused - incorrect / missing items";
    case "NONE":
      return "None";
    case "OTHER":
      return "Other";
    case "UNAVAILABLE":
      return "Recipient unavailable";
  }
}
