import LoadingButton from "@mui/lab/LoadingButton";
import { Menu, MenuItem } from "@mui/material";
import { Task, Warehouse } from "@quickcommerceltd/zephyr-types";
import { FC, useState } from "react";
import { useWarehouses } from "../../Warehouse/hooks/useWarehouses";
import { TaskAssignWarehouseDialog } from "./TaskAssignWarehouseDialog";

interface Props {
  task: Task;
  isDisabled?: boolean;
}

export const TaskAssignWarehouseSelect: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [nextWarehouse, setNextWarehouse] = useState<Warehouse>();
  const warehouses = useWarehouses((state) => state.warehouses);
  const filteredWarehouses = warehouses.filter(
    (w) => w.id !== props.task.warehouseId
  );

  return (
    <>
      <LoadingButton
        disabled={props.isDisabled}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
      >
        {props.task.warehouseId ? "Reassign" : "Assign"}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {filteredWarehouses.map((w) => (
          <MenuItem
            key={w.id}
            onClick={() => {
              setAnchorEl(null);
              setNextWarehouse(w);
            }}
          >
            {w.name}
          </MenuItem>
        ))}
      </Menu>
      <TaskAssignWarehouseDialog
        isOpen={!!nextWarehouse}
        nextWarehouse={nextWarehouse}
        task={props.task}
        close={() => setNextWarehouse(undefined)}
      />
    </>
  );
};
