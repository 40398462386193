import { Rider, Task } from "@quickcommerceltd/zephyr-types";
import { useEffect, useState } from "react";
import { useAppConfig } from "../../App/hooks/useAppConfig";
import { WARNING_KEYFRAMES } from "../../Sidebar/components/SidebarListItem";
import { getSidebarWarningPriority } from "../../Sidebar/helpers/getSidebarWarningPriority";
import { SidebarWarning } from "../../Sidebar/types/SidebarWarning";

export function useTaskSidebarWarning(
  task: Task,
  rider?: Rider
): SidebarWarning | undefined {
  const [, rerender] = useState(0);
  const warningInMinutes = useAppConfig(
    (store) => store.appConfig?.warningInMinutes
  );

  useEffect(() => {
    // TODO: Replace interval with timeouts to the next priority!
    const interval = setInterval(() => rerender((count) => count + 1), 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // This effect restarts all animations to keep them in sync.
    document
      .getAnimations()
      .filter((a) => (a as any).animationName === WARNING_KEYFRAMES.name)
      .forEach((animation) => {
        animation.cancel();
        animation.play();
      });
  }, []);

  if (!warningInMinutes) return;

  // Not assigned non 3P rider
  if (task.status === "UNASSIGNED") {
    const priority = getSidebarWarningPriority(
      warningInMinutes.taskNotAssigned,
      task.createdAt
    );

    if (priority > 0) {
      return {
        type: "NOT_ASSIGNED",
        priority,
        timestamp: task.createdAt,
      };
    }
  }

  // Not assigned 3P rider
  if (
    task.status === "PENDING_RIDER_ASSIGNMENT" &&
    task.requestedThirdPartyProviderAt
  ) {
    const priority = getSidebarWarningPriority(
      warningInMinutes.taskNotAssigned,
      task.requestedThirdPartyProviderAt
    );

    if (priority > 0) {
      return {
        type: "NOT_ASSIGNED_WHEN_PENDING",
        priority,
        timestamp: task.requestedThirdPartyProviderAt,
      };
    }
  }

  // Not started
  if (
    task.assignedAt &&
    !task.startedAt &&
    task.status === "ASSIGNED" &&
    rider?.status === "WAITING_FOR_PACKED_ORDER"
  ) {
    const priority = getSidebarWarningPriority(
      warningInMinutes.taskNotStarted,
      task.assignedAt
    );

    if (priority > 0) {
      return {
        type: "NOT_STARTED",
        priority,
        timestamp: task.assignedAt,
      };
    }
  }
}
