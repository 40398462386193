import { Rider, RiderStatus } from "@quickcommerceltd/zephyr-types";

const IN_TRANSIT_STATUSES: RiderStatus[] = [
  "DELIVERING",
  "IDLE_AT_CUSTOMER",
  "RIDING_BACK_TO_STORE",
];

export function isRiderInTransit(rider: Rider) {
  return IN_TRANSIT_STATUSES.includes(rider.status);
}
