import { Task } from "@quickcommerceltd/zephyr-types";
import { useMemo, useRef } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { getTaskFromSnapshot } from "../../Auth/helpers/getDataFromDocumentSnapshot";
import { getTaskDocRef } from "../../Auth/helpers/getDocRef";

export function useTaskFromTaskId(
  taskId?: string
): [Task | undefined, boolean] {
  const taskRef = useRef<Task>();
  const [snapshot, isLoading] = useDocument(getTaskDocRef(taskId || "unknown"));

  useMemo(() => {
    if (snapshot?.metadata.hasPendingWrites) return;
    taskRef.current = getTaskFromSnapshot(snapshot);
  }, [snapshot]);

  return [taskRef.current, taskRef.current ? false : isLoading];
}
