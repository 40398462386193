import { List, Typography } from "@mui/material";
import { Task } from "@quickcommerceltd/zephyr-types";
import { FC } from "react";
import { ZSecondaryAccordion } from "../../Common/components/ZSecondaryAccordion";
import { SidebarTaskListItem } from "../../Sidebar/components/SidebarTaskListItem";
import { TASK_STATUSES } from "../constants/TASK_STATUSES";
import { getTaskStatusTranslation } from "../helpers/getTaskStatusTranslation";

interface Props {
  tasks: Task[];
}

export const TasksByStatusAccordions: FC<Props> = (props) => {
  if (!props.tasks.length) {
    return <Typography p={2}>No tasks were found.</Typography>;
  }

  return (
    <>
      {TASK_STATUSES.map((taskStatus) => {
        const filteredTasks = props.tasks.filter((t) => t.status === taskStatus);

        if (!filteredTasks.length) return null;

        return (
          <ZSecondaryAccordion
            key={taskStatus}
            title={getTaskStatusTranslation({ status: taskStatus })}
          >
            <List>
              {filteredTasks.map((t) => (
                <SidebarTaskListItem key={t.id} task={t} pl={3} />
              ))}
            </List>
          </ZSecondaryAccordion>
        );
      })}
    </>
  );
};
