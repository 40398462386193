import create from "zustand";

export interface MapStore {
  focusedRiderId?: string;
  focusedTaskIds?: string[];
  focusedMapTaskId?: string;
  hasSetNextTaskIds: boolean;
  hasSetNextRiderId: boolean;
  setFocusedRiderId: (focusedRiderId: string) => void;
  setFocusedTaskIds: (focusedTaskIds: string[]) => void;
  setFocusedMapTaskId: (focusedTaskId?: string) => void;
  removeFocusedTaskIds: () => void;
  removeFocusedRiderId: () => void;
}

export const useMap = create<MapStore>((set, get) => ({
  hasSetNextTaskIds: false,
  hasSetNextRiderId: false,
  setFocusedRiderId: (focusedRiderId) => {
    set({ focusedRiderId, hasSetNextRiderId: true });
  },
  setFocusedTaskIds: (focusedTaskIds) => {
    set({ focusedTaskIds, hasSetNextTaskIds: true });
  },
  setFocusedMapTaskId: (taskId) => set({ focusedMapTaskId: taskId }),
  removeFocusedTaskIds: () => {
    set({ hasSetNextTaskIds: false });
    setTimeout(() => {
      if (get().hasSetNextTaskIds) return;
      set({ focusedTaskIds: undefined });
    }, 100);
  },
  removeFocusedRiderId: () => {
    set({ hasSetNextRiderId: false });
    setTimeout(() => {
      if (get().hasSetNextRiderId) return;
      set({ focusedRiderId: undefined });
    }, 100);
  },
}));
