import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { RiderStatus, TaskStatus } from "@quickcommerceltd/zephyr-types";
import { FC, useCallback } from "react";
import { toggleArray } from "../../Common/helpers/toggleArray";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { RiderStatusIcon } from "../../Rider/components/RiderStatusIcon";
import { RIDER_STATUSES } from "../../Rider/constants/RIDER_STATUSES";
import { getRiderStatusTranslation } from "../../Rider/helpers/getRiderStatusTranslation";
import { TaskStatusIcon } from "../../Task/components/TaskStatusIcon";
import { TASK_STATUSES } from "../../Task/constants/TASK_STATUSES";
import { getTaskStatusTranslation } from "../../Task/helpers/getTaskStatusTranslation";

export const FilterStatusView: FC = () => {
  const localStorage = useLocalStorage();
  const taskStatuses = localStorage.data.filteredTaskStatuses || TASK_STATUSES;
  const riderStatuses =
    localStorage.data.filteredRiderStatuses || RIDER_STATUSES;
  const areAllTaskStatusesEnabled =
    taskStatuses.length === TASK_STATUSES.length;
  const areAllRiderStatusesEnabled =
    riderStatuses.length === RIDER_STATUSES.length;

  const toggleAllTaskStatuses = useCallback(() => {
    localStorage.set({
      filteredTaskStatuses: areAllTaskStatusesEnabled ? [] : undefined,
    });
  }, [areAllTaskStatusesEnabled, localStorage]);

  const toggleAllRiderStatuses = useCallback(() => {
    localStorage.set({
      filteredRiderStatuses: areAllRiderStatusesEnabled ? [] : undefined,
    });
  }, [areAllRiderStatusesEnabled, localStorage]);

  const toggleTaskStatus = useCallback(
    (taskStatus: TaskStatus) => {
      let next = toggleArray(taskStatus, taskStatuses, TASK_STATUSES);

      // We don't show all statuses, but we merge:
      // PENDING_RIDER_ASSIGNMENT with ASSIGNED
      if (taskStatus === "ASSIGNED" && next) {
        next = toggleArray("PENDING_RIDER_ASSIGNMENT", next, TASK_STATUSES);
      }

      localStorage.set({ filteredTaskStatuses: next });
    },
    [localStorage, taskStatuses]
  );

  const toggleRiderStatus = useCallback(
    (riderStatus: RiderStatus) => {
      let next = toggleArray(riderStatus, riderStatuses, RIDER_STATUSES);

      // We don't show all statuses, but we merge:
      // IDLE_AT_CUSTOMER with DELIVERING and
      // PICKING_UP with RIDING_BACK_TO_STORE
      if (riderStatus === "DELIVERING" && next) {
        next = toggleArray("IDLE_AT_CUSTOMER", next, RIDER_STATUSES);
      }

      if (riderStatus === "RIDING_BACK_TO_STORE" && next) {
        next = toggleArray("PICKING_UP", next, RIDER_STATUSES);
      }

      localStorage.set({ filteredRiderStatuses: next });
    },
    [localStorage, riderStatuses]
  );

  return (
    <Box display="flex" p={2}>
      <Box flex={1}>
        <Typography fontWeight="bold" mb={1}>
          Tasks
        </Typography>
        <FormGroup>
          <FormControlLabel
            label="Select all tasks"
            control={
              <Checkbox
                onChange={toggleAllTaskStatuses}
                checked={areAllTaskStatusesEnabled}
              />
            }
          />
          {TASK_STATUSES.filter(
            (taskStatus) => taskStatus !== "PENDING_RIDER_ASSIGNMENT"
          ).map((taskStatus) => (
            <FormControlLabel
              key={taskStatus}
              control={
                <Checkbox
                  checked={taskStatuses.includes(taskStatus)}
                  onChange={() => toggleTaskStatus(taskStatus)}
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <TaskStatusIcon task={{ status: taskStatus }} />
                  <Typography ml={0.5}>
                    {getTaskStatusTranslation({ status: taskStatus })}
                  </Typography>
                </Box>
              }
            />
          ))}
        </FormGroup>
      </Box>
      <Box flex={1} pl={1}>
        <Typography fontWeight="bold" mb={1}>
          Riders
        </Typography>
        <FormGroup>
          <FormControlLabel
            label="Select all riders"
            control={
              <Checkbox
                onChange={toggleAllRiderStatuses}
                checked={areAllRiderStatusesEnabled}
              />
            }
          />
          {RIDER_STATUSES.filter(
            (riderStatus) =>
              !["IDLE_AT_CUSTOMER", "PICKING_UP"].includes(riderStatus)
          ).map((riderStatus) => (
            <FormControlLabel
              key={riderStatus}
              control={
                <Checkbox
                  checked={riderStatuses.includes(riderStatus)}
                  onChange={() => toggleRiderStatus(riderStatus)}
                />
              }
              label={
                <Box display="flex" alignItems="center">
                  <RiderStatusIcon riderStatus={riderStatus} />
                  <Typography ml={0.5}>
                    {getRiderStatusTranslation(riderStatus)}
                  </Typography>
                </Box>
              }
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
};
