import { Task } from "@quickcommerceltd/zephyr-types";
import { useRiders } from "./useRiders";

export function useRiderFromTask(task?: Task) {
  return useRiders((state) =>
    state.riders.find(
      (r) => r.id === (task?.riderId || "not-set")
    )
  );
}
