import { Rider } from "@quickcommerceltd/zephyr-types";
import { getThirdPartyProviderTranslation } from "../../Common/helpers/getThirdPartyProviderTranslation";

export function getRiderName(rider: Rider) {
  if (rider.thirdPartyProvider) {
    return `${rider.name} (${getThirdPartyProviderTranslation(
      rider.thirdPartyProvider
    )})`;
  }

  return rider.name || rider.phone;
}
