import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { getTaskStatusTranslation } from "../../Task/helpers/getTaskStatusTranslation";

export function useTaskFilterLabel() {
  const localStorage = useLocalStorage();
  const statuses = localStorage.data.filteredTaskStatuses;

  if (statuses) {
    if (statuses.length) {
      if (statuses.length === 1) {
        return getTaskStatusTranslation({ status: statuses[0] });
      } else {
        return "Some Tasks";
      }
    } else {
      return "No Tasks";
    }
  } else {
    return "All Tasks";
  }
}
