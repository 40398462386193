import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HistoryIcon from "@mui/icons-material/History";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MopedOutlinedIcon from "@mui/icons-material/MopedOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  List,
  Typography,
} from "@mui/material";
import { TaskLog } from "@quickcommerceltd/zephyr-types";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { COLOR } from "../../App/constants/COLOR";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { FragmentWithBreaks } from "../../Common/components/FragmentWithBreaks";
import { ZAccordion } from "../../Common/components/ZAccordion";
import { ZDetailsListItem } from "../../Common/components/ZDetailsListItem";
import { LogDialog } from "../../Log/components/LogDialog";
import { TaskLogListItem } from "../../Log/components/TaskLogListItem";
import { useTaskLogs } from "../../Log/hooks/useTaskLogs";
import { useRiderFromTask } from "../../Rider/hooks/useRiderFromTask";
import { SidebarEmptyListItem } from "../../Sidebar/components/SidebarEmptyListItem";
import { useWarehouses } from "../../Warehouse/hooks/useWarehouses";
import { TASK_COMPLETE_STATUSES } from "../constants/TASK_COMPLETE_STATUSES";
import { getTaskCloneReasonTranslation } from "../helpers/getTaskCloneReasonTranslation";
import { getTaskDeliveryOptionTranslation } from "../helpers/getTaskDeliveryOptionTranslation";
import { getTaskFailureReasonTranslation } from "../helpers/getTaskFailureReasonTranslation";
import { getTaskPickingStatusTranslation } from "../helpers/getTaskPickingStatusTranslation";
import { getTaskStatusTranslation } from "../helpers/getTaskStatusTranslation";
import { isTaskStatusStacked } from "../helpers/isTaskStatusStacked";
import { useTaskFromTaskId } from "../hooks/useTaskFromTaskId";
import { TaskAssignRiderSelect } from "./TaskAssignRiderSelect";
import { TaskAssignWarehouseSelect } from "./TaskAssignWarehouseSelect";
import { TaskCloneDialog } from "./TaskCloneDialog";
import { TaskCompleteDialog } from "./TaskCompleteDialog";
import { TaskOnTimeView } from "./TaskOnTimeView";
import { TaskPickingStatusSelect } from "./TaskPickingStatusSelect";
import { TaskStatusIcon } from "./TaskStatusIcon";
import { TaskUpsertDialog } from "./TaskUpsertDialog";

export const TaskDetailsView: FC = () => {
  const params = useParams<"taskId">();
  const [task, isLoadingTask] = useTaskFromTaskId(params.taskId);
  const warehouses = useWarehouses((state) => state.warehouses);
  const hasPermission = useHasPermission();
  const [taskLogs, isLoadingTaskLogs, taskLogsError] = useTaskLogs(
    params.taskId
  );
  const rider = useRiderFromTask(task);
  const [selectedTaskLog, setSelectedTaskLog] = useState<TaskLog>();
  const [isTaskCloneDialogOpen, setIsTaskCloneDialogOpen] = useState(false);
  const [isTaskLogDialogOpen, setIsTaskLogDialogOpen] = useState(false);
  const [isEditTaskDialogOpen, setIsEditTaskDialogOpen] = useState(false);
  const [isTaskCompleteDialogOpen, setIsTaskCompleteDialogOpen] =
    useState(false);

  const isCompleted = task?.status
    ? TASK_COMPLETE_STATUSES.includes(task.status)
    : true;
  const isBlocked = task?.status
    ? ["DELIVERING", ...TASK_COMPLETE_STATUSES].includes(task.status) ||
      isTaskStatusStacked(task.status, rider?.status)
    : true;

  const isEditDisabled = !hasPermission("UPSERT_TASK") || isBlocked;
  const isAssignRiderDisabled =
    !hasPermission("UPDATE_TASK_RIDER") || isBlocked;
  const isAssignWarehouseDisabled =
    !hasPermission("UPDATE_TASK_WAREHOUSE") || isBlocked;
  const isCompleteDisabled = !hasPermission("COMPLETE_TASK") || isCompleted;

  return (
    <>
      {!task && isLoadingTask && (
        <Box p={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!task && !isLoadingTask && (
        <Typography px={2} fontWeight={700} fontSize={21}>
          No task found.
        </Typography>
      )}
      {task && (
        <>
          <Typography px={2} fontWeight={700} fontSize={21}>
            Task:{" "}
            {task.externalOrderNumber || task.id.substring(task.id.length - 8)}
          </Typography>
          <Box
            p={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button color="inherit" disabled>
              <Box display="flex" alignItems="center">
                <TaskStatusIcon task={task} riderStatus={rider?.status} />
                <Typography ml={1} fontWeight={500} color={COLOR.white[0]}>
                  {getTaskStatusTranslation(task, rider?.status)}
                </Typography>
              </Box>
            </Button>
            {!task.completedAt && <TaskOnTimeView task={task} />}
          </Box>
          <ZAccordion
            title="Details"
            titleIcon={<LocationOnIcon sx={{ color: COLOR.blue[50] }} />}
          >
            <List>
              {task.failureReason && (
                <ZDetailsListItem
                  title={`Failure: ${getTaskFailureReasonTranslation(
                    task.failureReason
                  )}`}
                  iconComponent={WarningAmberIcon}
                >
                  {!task.failureNotes && "No notes"}
                  {task.failureNotes && (
                    <FragmentWithBreaks
                      lines={task.failureNotes?.split("\n")}
                    />
                  )}
                </ZDetailsListItem>
              )}
              {task.clonedFromTaskId && (
                <ZDetailsListItem
                  title="Cloned From"
                  iconComponent={ContentCopyIcon}
                  to={`/tasks/${task.clonedFromTaskId}`}
                >
                  <FragmentWithBreaks
                    lines={[
                      task.clonedFromTaskId.substring(
                        task.clonedFromTaskId.length - 8
                      ),
                      task.cloneReason &&
                        `Clone Reason: ${getTaskCloneReasonTranslation(
                          task.cloneReason
                        )}`,
                    ]}
                  />
                </ZDetailsListItem>
              )}
              <ZDetailsListItem
                title="Rider"
                iconComponent={MopedOutlinedIcon}
                to={task.riderId && `/tasks/${task.id}/riders/${task.riderId}`}
                testid="TaskAssignedToListItem"
                secondaryAction={
                  <TaskAssignRiderSelect
                    isDisabled={isAssignRiderDisabled}
                    task={task}
                  />
                }
              >
                {rider ? (
                  <FragmentWithBreaks lines={[rider.name, rider.phone]} />
                ) : task.riderId ? (
                  "Rider from another store"
                ) : (
                  "Unassigned"
                )}
              </ZDetailsListItem>
              <ZDetailsListItem
                title="Recipient"
                iconComponent={PersonOutlinedIcon}
                testid="TaskRecipientListItem"
                onClick={
                  isEditDisabled
                    ? undefined
                    : () => setIsEditTaskDialogOpen(true)
                }
              >
                {task.isAnonymized ? (
                  "Anonymized"
                ) : (
                  <FragmentWithBreaks
                    lines={[
                      [task.customer.firstName, task.customer.lastName]
                        .filter((i) => i)
                        .join(" "),
                      task.customer.phone,
                    ]}
                  />
                )}
              </ZDetailsListItem>
              <ZDetailsListItem
                title="Destination"
                iconComponent={LocationOnOutlinedIcon}
                testid="TaskDestinationListItem"
                onClick={
                  isEditDisabled
                    ? undefined
                    : () => setIsEditTaskDialogOpen(true)
                }
              >
                <FragmentWithBreaks
                  lines={[
                    task.address.companyName,
                    task.address.streetAddress1,
                    task.address.streetAddress2,
                    [task.address.city, task.address.postalCode]
                      .filter((i) => i)
                      .join(" "),
                  ]}
                />
              </ZDetailsListItem>
              <ZDetailsListItem
                title="Store"
                iconComponent={WarehouseOutlinedIcon}
                testid="TaskStoreListItem"
                secondaryAction={
                  <TaskAssignWarehouseSelect
                    isDisabled={isAssignWarehouseDisabled}
                    task={task}
                  />
                }
              >
                {warehouses.find((w) => w.id === task.warehouseId)?.name ||
                  "Not set"}
              </ZDetailsListItem>
              <ZDetailsListItem
                title="Order"
                iconComponent={InfoOutlinedIcon}
                testid="TaskOrderNumberListItem"
              >
                Number: <strong>{task.externalOrderNumber || "Not set"}</strong>
                <br />
                ID: <strong>{task.externalOrderId || "Not set"}</strong>
              </ZDetailsListItem>
              <ZDetailsListItem
                title="Picking Status"
                iconComponent={ShoppingBasketOutlinedIcon}
                secondaryAction={<TaskPickingStatusSelect task={task} />}
              >
                {getTaskPickingStatusTranslation(task.pickingStatus)}
              </ZDetailsListItem>
              <ZDetailsListItem
                title="Delivery Notes"
                iconComponent={NoteAltOutlinedIcon}
                testid="TaskDeliveryNotesListItem"
              >
                Instructions:{" "}
                <strong>
                  {getTaskDeliveryOptionTranslation(
                    task.address.deliveryOption
                  )}
                </strong>
                <br />
                Notes: <strong>{task.noteFromCustomer || "Not set"}</strong>
                <br />
                Customer Service:{" "}
                <strong>
                  {task.customer.noteFromCustomerService || "Not set"}
                </strong>
              </ZDetailsListItem>
              <ZDetailsListItem
                title="Delivery"
                iconComponent={AccessTimeIcon}
                testid="EstimatedDeliveryListItem"
              >
                Promised:{" "}
                <strong>
                  {task.promisedDeliveryTimeInMinutes
                    ? DateTime.fromMillis(task.createdAt.toMillis())
                        .plus({ minutes: task.promisedDeliveryTimeInMinutes })
                        .toFormat("HH:mm")
                    : "Not set"}
                </strong>
                <br />
                Estimated:{" "}
                <strong>
                  {task.estimatedDeliveryAt
                    ? DateTime.fromMillis(
                        task.estimatedDeliveryAt?.toMillis()
                      ).toFormat("HH:mm")
                    : "Not set"}
                </strong>
              </ZDetailsListItem>
            </List>
          </ZAccordion>
          <ZAccordion
            title="Timeline"
            titleIcon={<HistoryIcon sx={{ color: COLOR.blue[50] }} />}
          >
            {isLoadingTaskLogs && (
              <Box p={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {taskLogsError && (
              <Box p={2}>
                <Alert severity="error">{taskLogsError.message}</Alert>
              </Box>
            )}
            {taskLogs && (
              <List>
                {taskLogs.length === 0 && (
                  <SidebarEmptyListItem
                    message="No task logs"
                    iconComponent={CancelOutlinedIcon}
                    color={COLOR.blue[50]}
                  />
                )}
                {taskLogs?.map((taskLog, index) => (
                  <TaskLogListItem
                    key={index}
                    taskLog={taskLog}
                    isFirstItem={index === 0}
                    isLastItem={index === taskLogs.length - 1}
                    onClick={() => {
                      setSelectedTaskLog(taskLog);
                      setIsTaskLogDialogOpen(true);
                    }}
                  />
                ))}
              </List>
            )}
          </ZAccordion>
          <Box py={2} px={2} display="flex" justifyContent="flex-end">
            <Button
              color="success"
              disabled={isCompleteDisabled}
              onClick={() => setIsTaskCompleteDialogOpen(true)}
              data-testid="CompleteTaskButton"
            >
              Complete
            </Button>
            <Button
              color="warning"
              disabled={!hasPermission("CLONE_TASK")}
              onClick={() => setIsTaskCloneDialogOpen(true)}
              data-testid="CloneTaskButton"
            >
              Clone
            </Button>
            <Button
              disabled={isEditDisabled}
              onClick={() => setIsEditTaskDialogOpen(true)}
              data-testid="EditTaskButton"
            >
              Edit
            </Button>
          </Box>
          <TaskCompleteDialog
            task={task}
            isOpen={isTaskCompleteDialogOpen}
            close={() => setIsTaskCompleteDialogOpen(false)}
          />
          <TaskCloneDialog
            task={task}
            isOpen={isTaskCloneDialogOpen}
            close={() => setIsTaskCloneDialogOpen(false)}
          />
          <TaskUpsertDialog
            task={task}
            isOpen={isEditTaskDialogOpen}
            close={() => setIsEditTaskDialogOpen(false)}
          />
          <LogDialog
            title="Task Log"
            log={selectedTaskLog}
            isOpen={isTaskLogDialogOpen}
            close={() => setIsTaskLogDialogOpen(false)}
          />
        </>
      )}
    </>
  );
};
