import { Rider, RiderStatus } from "@quickcommerceltd/zephyr-types";

const NOT_STARTED_STATUSES: RiderStatus[] = [
  "PICKING_UP",
  "IDLE_AT_STORE",
  "WAITING_FOR_PACKED_ORDER",
];

export function isRiderNotStarted(rider: Rider) {
  return NOT_STARTED_STATUSES.includes(rider.status);
}
