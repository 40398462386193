import { RiderStatus, Task, TaskStatus } from "@quickcommerceltd/zephyr-types";
import { isTaskStatusStacked } from "./isTaskStatusStacked";

export function getTaskStatusTranslation(
  task: Partial<Task> & { status: TaskStatus },
  riderStatus?: RiderStatus
): string {
  switch (task.status) {
    case "PENDING_RIDER_ASSIGNMENT":
      return "Pending";
    case "ASSIGNED":
      return isTaskStatusStacked(task.status, riderStatus)
        ? "Stacked"
        : "Assigned";
    case "DELIVERED":
      return "Successful";
    case "DELIVERING":
      return task.hasArrivedAtCustomer ? "Handing over" : "In Delivery";
    case "FAILED":
      return "Failed";
    case "UNASSIGNED":
      return "Unassigned";
  }
}
