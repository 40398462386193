import { RiderStatus, Task, Warehouse } from "@quickcommerceltd/zephyr-types";
import { getDistanceInMiles } from "../../Map/helpers/getDistance";
import { RiderWithCoords } from "../types/RiderWithCoords";
import { getRiderStatusTranslation } from "./getRiderStatusTranslation";
import { validateUnassignTaskFromRider } from "./validateUnassignTaskFromRider";

const STORE_EXIT_DISTANCE_IN_MILES = 0.1;
const ASSIGNABLE_RIDER_STATUSES: RiderStatus[] = [
  "IDLE_AT_STORE",
  "WAITING_FOR_PACKED_ORDER",
  "RIDING_BACK_TO_STORE",
];

interface Validation {
  isValid: boolean;
  error?: Error;
}

export function validateAssignTaskToRider(
  nextRider: RiderWithCoords,
  task?: Task,
  warehouse?: Warehouse
): Validation {
  try {
    if (!warehouse) {
      throw Error("No store selected.");
    }

    if (!task) {
      throw Error("Missing task.");
    }

    if (task.riderId === nextRider.id) {
      throw Error("Task is already assigned to this rider.");
    }

    if (task.thirdPartyProvider) {
      throw Error("Assigning a 3P task to another rider not allowed.");
    }

    if (nextRider.thirdPartyProvider) {
      throw Error("Assigning a task directly to a 3P rider is not allowed.");
    }

    if (nextRider.status === "DELIVERING") {
      if (!nextRider.coords) {
        throw Error(
          "Assigning a task to a rider with an unknown location is not allowed."
        );
      }

      if (
        getDistanceInMiles(nextRider.coords, warehouse.address) >
        STORE_EXIT_DISTANCE_IN_MILES
      ) {
        throw Error(
          "Assigning a task to an already started rider, who is not close to the store is not allowed."
        );
      }
    }

    if (!ASSIGNABLE_RIDER_STATUSES.includes(nextRider.status)) {
      throw Error(
        `Assigning a task to rider who is "${getRiderStatusTranslation(
          nextRider.status
        )}" is not allowed.`
      );
    }

    if (task.riderId) {
      const { error } = validateUnassignTaskFromRider(task);

      if (error) {
        throw error;
      }
    }

    return { isValid: true };
  } catch (error: any) {
    return { isValid: false, error };
  }
}
