import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { Rider, RiderStatus } from "@quickcommerceltd/zephyr-types";
import { FC, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { updateRiderStatusCallable } from "../../Auth/callables/rider/updateRiderStatusCallable";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { logError } from "../../Common/helpers/logError";
import { RIDER_STATUSES } from "../constants/RIDER_STATUSES";
import { getRiderStatusTranslation } from "../helpers/getRiderStatusTranslation";
import { RiderStatusIcon } from "./RiderStatusIcon";

interface Props {
  rider: Rider;
  fontWeight?: number;
}

export const RiderStatusSelect: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const hasPermission = useHasPermission();
  const [isLoading, setIsLoading] = useState(false);

  const onClick = useCallback(
    async (riderStatus: RiderStatus) => {
      try {
        setIsLoading(true);
        setAnchorEl(null);

        if (!props.rider.id) return;

        await updateRiderStatusCallable({
          riderId: props.rider.id,
          status: riderStatus,
        });
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [props.rider.id]
  );

  const riderStatuses = useMemo<RiderStatus[]>(
    () =>
      hasPermission("UPDATE_RIDER_STATUS_TO_ANYTHING")
        ? RIDER_STATUSES.filter(
            (riderStatus) => riderStatus !== "IDLE_AT_CUSTOMER"
          )
        : ["OFF_DUTY"],
    [hasPermission]
  );

  if (!props.rider.id) return null;

  return (
    <>
      <Button
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        disabled={!hasPermission("UPDATE_RIDER_STATUS") || isLoading}
      >
        <Box display="flex" alignItems="center">
          <RiderStatusIcon
            riderStatus={props.rider.status}
            size={20}
            isLoading={isLoading}
          />
          <Typography ml={1} fontWeight={props.fontWeight}>
            {getRiderStatusTranslation(props.rider.status)}
          </Typography>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {riderStatuses.map((riderStatus) => (
          <MenuItem key={riderStatus} onClick={() => onClick(riderStatus)}>
            {getRiderStatusTranslation(riderStatus)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
