import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HistoryIcon from "@mui/icons-material/History";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MopedOutlinedIcon from "@mui/icons-material/MopedOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import { RiderLog } from "@quickcommerceltd/zephyr-types";
import { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { COLOR } from "../../App/constants/COLOR";
import { PlatformOsIcon } from "../../Common/components/PlatformOsIcon";
import { ZAccordion } from "../../Common/components/ZAccordion";
import { ZDetailsListItem } from "../../Common/components/ZDetailsListItem";
import { getPlatformOsTranslation } from "../../Common/helpers/getPlatformTranslation";
import { LogDialog } from "../../Log/components/LogDialog";
import { RiderLogListItem } from "../../Log/components/RiderLogListItem";
import { useRiderLogs } from "../../Log/hooks/useRiderLogs";
import { SidebarEmptyListItem } from "../../Sidebar/components/SidebarEmptyListItem";
import { TasksByStatusAccordions } from "../../Task/components/TasksByStatusAccordions";
import { useTasksByRiderId } from "../../Task/hooks/useTasksByRiderId";
import { useWarehouseFromWarehouseId } from "../../Warehouse/hooks/useWarehouseFromWarehouseId";
import { useHoverRider } from "../hooks/useHoverRider";
import { useRiderFromRiderId } from "../hooks/useRiderFromRiderId";
import { RiderAssignWarehouseSelect } from "./RiderAssignWarehouseSelect";
import { RiderStatusSelect } from "./RiderStatusSelect";

export const RiderDetailsView: FC = () => {
  const params = useParams<"riderId">();
  const [rider, isLoadingRider] = useRiderFromRiderId(params.riderId);
  const warehouse = useWarehouseFromWarehouseId(rider?.assignedWarehouseId);
  const [riderLogs, isLoadingRiderLogs, riderLogsError] = useRiderLogs(
    params.riderId
  );
  const [selectedRiderLog, setSelectedRiderLog] = useState<RiderLog>();
  const [isRiderLogDialogOpen, setIsRiderLogDialogOpen] = useState(false);
  const tasks = useTasksByRiderId(params.riderId);
  const { onMouseOut, onMouseOver } = useHoverRider(tasks, rider?.id);

  return (
    <>
      {!rider && isLoadingRider && (
        <Box p={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!rider && !isLoadingRider && (
        <Typography px={2} fontWeight={700} fontSize={21}>
          No rider found.
        </Typography>
      )}
      {rider && (
        <>
          <Typography px={2} fontWeight={700} fontSize={21}>
            {rider.name}
          </Typography>
          <Box p={1} display="flex" justifyContent="space-between">
            <RiderStatusSelect rider={rider} fontWeight={500} />
            <IconButton
              color="inherit"
              component={Link}
              to={`/riders/${rider.id}/history`}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              data-testid="CreateTaskIconButton"
            >
              <VisibilityOutlinedIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
          <ZAccordion
            title="Details"
            titleIcon={<MopedOutlinedIcon sx={{ color: COLOR.blue[50] }} />}
          >
            <List>
              <ZDetailsListItem
                title={rider.phone}
                iconComponent={PhoneOutlinedIcon}
                testid="RiderDetailsItemPhone"
              />
              <ZDetailsListItem
                title="Assigned Store"
                iconComponent={WarehouseOutlinedIcon}
                testid="RiderDetailsItemAssignedToStore"
                secondaryAction={<RiderAssignWarehouseSelect rider={rider} />}
              >
                {warehouse?.name || <em>None</em>}
              </ZDetailsListItem>
              {!rider.isThirdPartyProvider && (
                <ZDetailsListItem
                  title="Device"
                  iconComponent={PhoneIphoneOutlinedIcon}
                  testid="RiderDetailsItemDevice"
                >
                  App Version:{" "}
                  <strong>{rider.appVersion || "Not found"}</strong>
                  <br />
                  Platform:{" "}
                  <PlatformOsIcon
                    platformOs={rider.appPlatformOs}
                    sx={{ fontSize: 12, mr: 0.5, position: "relative", top: 1 }}
                  />
                  <strong>
                    {getPlatformOsTranslation(rider.appPlatformOs)}{" "}
                    {rider.appPlatformVersion || ""}
                  </strong>
                </ZDetailsListItem>
              )}
            </List>
          </ZAccordion>
          <ZAccordion
            title="Tasks"
            titleIcon={<LocationOnIcon sx={{ color: COLOR.blue[50] }} />}
          >
            <TasksByStatusAccordions tasks={tasks} />
          </ZAccordion>
          <ZAccordion
            title="Timeline"
            titleIcon={<HistoryIcon sx={{ color: COLOR.blue[50] }} />}
          >
            {isLoadingRiderLogs && (
              <Box p={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {riderLogsError && (
              <Box p={2}>
                <Alert severity="error">{riderLogsError.message}</Alert>
              </Box>
            )}
            {riderLogs && (
              <List>
                {riderLogs.length === 0 && (
                  <SidebarEmptyListItem
                    message="No rider logs"
                    iconComponent={CancelOutlinedIcon}
                    color={COLOR.blue[50]}
                  />
                )}
                {riderLogs?.map((riderLog, index) => (
                  <RiderLogListItem
                    key={index}
                    riderLog={riderLog}
                    isFirstItem={index === 0}
                    isLastItem={index === riderLogs.length - 1}
                    onClick={() => {
                      setSelectedRiderLog(riderLog);
                      setIsRiderLogDialogOpen(true);
                    }}
                  />
                ))}
              </List>
            )}
          </ZAccordion>
          <LogDialog
            title="Rider Log"
            log={selectedRiderLog}
            isOpen={isRiderLogDialogOpen}
            close={() => setIsRiderLogDialogOpen(false)}
          />
        </>
      )}
    </>
  );
};
