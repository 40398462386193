import { TaskFailureReason } from "@quickcommerceltd/zephyr-types";

export const TASK_FAILURE_REASONS: TaskFailureReason[] = [
  "ORDER_CANCELLED",
  "DELIVERY_INCIDENT",
  "DESTINATION_INCORRECT",
  "ITEM_DAMAGED",
  "ITEM_INCORRECT",
  "UNAVAILABLE",
  "OTHER",
  "NONE",
];
