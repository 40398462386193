import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { getRiderStatusTranslation } from "../../Rider/helpers/getRiderStatusTranslation";

export function useRiderFilterLabel() {
  const localStorage = useLocalStorage();
  const statuses = localStorage.data.filteredRiderStatuses;

  if (statuses) {
    if (statuses.length) {
      if (statuses.length === 1) {
        return getRiderStatusTranslation(statuses[0]);
      } else {
        return "Some Riders";
      }
    } else {
      return "No Riders";
    }
  } else {
    return "All Riders";
  }
}
