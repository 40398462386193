import { FormControlLabel, Switch, SxProps, Theme } from "@mui/material";
import { Control, Controller, FieldPath } from "react-hook-form";

interface Props<T> {
  control: Control<T>;
  name: FieldPath<T>;
  label: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

export function ControlledSwitch<T>(props: Props<T>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          disabled={props.disabled}
          checked={!!field.value}
          control={<Switch />}
          label={props.label}
        />
      )}
    />
  );
}
