import "@react-google-maps/api";
import { ZephyrTypesConfig } from "@quickcommerceltd/zephyr-types";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom/client";
import { AppProvider } from "./App/components/AppProvider";
import { ENVIRONMENT } from "./App/constants/ENVIRONMENT";
import { logError } from "./Common/helpers/logError";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://8ad063780f664fafa94c48acf6d8a67e@o552852.ingest.sentry.io/6384135",
  integrations: [new BrowserTracing()],
  environment: ENVIRONMENT.nodeEnv,

  // tracesSampleRate sets the percentage of events related to performance
  // this is intenionally set lower in production to reduce total transactions.
  // 1.0 === 100% | 0.1 === 10%
  tracesSampleRate: ENVIRONMENT.nodeEnv === "development" ? 1.0 : 0.1,
});

ZephyrTypesConfig.logError = logError;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
