import { Tooltip } from "@mui/material";
import { Task } from "@quickcommerceltd/zephyr-types";
import { FC, forwardRef, ReactElement } from "react";
import { getTaskPickUpTooltip } from "../../Task/helpers/getTaskPickUpTooltip";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { SidebarTaskListItem } from "./SidebarTaskListItem";

interface Props {
  task: Task;
  isMoveToBottomAllowed?: boolean;
  pl?: number;
}

export const SidebarTaskListItemWithTooltip: FC<Props> = (props) => {
  const warehouse = useSelectedWarehouse();
  const tooltip = getTaskPickUpTooltip(props.task, warehouse);

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="right">
        <Container>
          <SidebarTaskListItem {...props} />
        </Container>
      </Tooltip>
    );
  } else {
    return <SidebarTaskListItem {...props} />;
  }
};

const Container = forwardRef<HTMLDivElement, { children: ReactElement }>(
  (props, ref) => {
    return <div {...props} ref={ref} />;
  }
);
