import { ThirdPartyProvider } from "@quickcommerceltd/zephyr-types";
import { useDrop } from "react-dnd";
import { DragItem } from "../../Sidebar/types/DragItem";
import { DropCollect } from "../../Sidebar/types/DropCollect";
import { DropResult } from "../../Sidebar/types/DropResult";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { validateAssignTaskToThirdPartyProvider } from "../helpers/validateAssignTaskToThirdPartyProvider";

export function useDropThirdPartyProvider(
  thirdPartyProvider: ThirdPartyProvider
) {
  const warehouse = useSelectedWarehouse();

  return useDrop<DragItem, DropResult, DropCollect>(
    () => ({
      accept: ["ASSIGNED_TASK", "UNASSIGNED_TASK"],
      drop: (_, monitor) => ({
        thirdPartyProvider,
        error: validateAssignTaskToThirdPartyProvider(
          thirdPartyProvider,
          monitor.getItem(),
          warehouse
        ).error,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        error: validateAssignTaskToThirdPartyProvider(
          thirdPartyProvider,
          monitor.getItem(),
          warehouse
        ).error,
      }),
    }),
    [thirdPartyProvider, warehouse]
  );
}
