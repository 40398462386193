import CircleIcon from "@mui/icons-material/Circle";
import { PickingStatus } from "@quickcommerceltd/zephyr-types";
import { FC } from "react";
import { COLOR } from "../../App/constants/COLOR";

const DEFAULT_ICON_SIZE = 8;

interface Props {
  pickingStatus?: PickingStatus;
  isLoading?: boolean;
  size?: number;
}

export const TaskPickingStatusIcon: FC<Props> = (props) => {
  const fontSize = props.size || DEFAULT_ICON_SIZE;

  switch (props.pickingStatus) {
    case "PICKING":
      return <CircleIcon sx={{ color: COLOR.yellow[500], fontSize }} />;
    case "PACKED":
      return <CircleIcon sx={{ color: COLOR.green[500], fontSize }} />;
    default:
      return <CircleIcon sx={{ color: COLOR.gray[200], fontSize }} />;
  }
};
