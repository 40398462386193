import { Rider } from "@quickcommerceltd/zephyr-types";
import { getRiderStatusMarkerUrl } from "../../Rider/helpers/getRiderStatusMarkerUrl";

export function getRiderMarkerIcon(
  isFocused: boolean,
  rider: Rider
): google.maps.Icon {
  const size = isFocused ? 20 : 16;

  return {
    url: getRiderStatusMarkerUrl(rider.status),
    scaledSize: new google.maps.Size(size, size),
  };
}
