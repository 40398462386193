import { AppConfig } from "@quickcommerceltd/zephyr-types";
import { onSnapshot } from "firebase/firestore";
import toast from "react-hot-toast";
import create from "zustand";
import { getAppConfigFromSnapshot } from "../../Auth/helpers/getDataFromDocumentSnapshot";
import { getAppConfigDocRef } from "../../Auth/helpers/getDocRef";

export interface AppConfigStore {
  isInitializing: boolean;
  appConfig?: AppConfig;
  subscribe: () => void;
  unsubscribe: () => void;
  unsubscribeFirestore?: () => void;
}

export const useAppConfig = create<AppConfigStore>((set, get) => ({
  isInitializing: true,
  subscribe: () => {
    const unsubscribeFirestore = onSnapshot(
      getAppConfigDocRef(),
      async (doc) => {
        set({
          isInitializing: false,
          appConfig: getAppConfigFromSnapshot(doc),
        });
      },
      (error: any) => {
        toast.error(error.message);
        set({ appConfig: undefined, isInitializing: false });
      }
    );

    set({ unsubscribeFirestore });
  },
  unsubscribe: () => {
    set({ appConfig: undefined, isInitializing: true });
    get().unsubscribeFirestore?.();
  },
}));
