import CircleIcon from "@mui/icons-material/Circle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Alert, Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Warehouse } from "@quickcommerceltd/zephyr-types";
import { FC, useMemo } from "react";
import { Link, Outlet } from "react-router-dom";
import { COLOR } from "../../App/constants/COLOR";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { DataGridPaper } from "../../Common/components/DataGridPaper";
import { LoadingView } from "../../Common/components/LoadingView";
import { getThirdPartyProviderImageSrc } from "../../Common/helpers/getThirdPartyProviderImageSrc";
import { getThirdPartyProviderTranslation } from "../../Common/helpers/getThirdPartyProviderTranslation";
import { useAllRiders } from "../../Rider/hooks/useAllRiders";
import { useUsers } from "../../User/hooks/useUsers";
import { WarehouseStatusIcon } from "../../Warehouse/components/WarehouseStatusIcon";
import { getWarehouseStatusTranslation } from "../../Warehouse/helpers/getWarehouseStatusTranslation";
import { useMyWarehouses } from "../../Warehouse/hooks/useMyWarehouses";
import { useIsFeatureEnabled } from "../hooks/useIsFeatureEnabled";
import { SettingsTitleView } from "./SettingsTitleView";

export const SettingsWarehousesView: FC = () => {
  const warehouses = useMyWarehouses();
  const [users, isLoadingUsers, usersError] = useUsers();
  const [riders, isLoadingRiders, ridersError] = useAllRiders();
  const hasPermission = useHasPermission();
  const isFeatureEnabled = useIsFeatureEnabled();
  const isLoading = isLoadingRiders || isLoadingUsers;
  const error = ridersError || usersError;
  const isLoaded = !isLoading && !error;

  const columns = useMemo<GridColDef<Warehouse>[]>(
    () => [
      {
        field: "name",
        headerName: "Store Name",
        width: 180,
        valueGetter: (params) => params.row.name || "None",
        renderCell: (params) => params.row.name || <em>None</em>,
      },

      {
        field: "shortName",
        headerName: "Code",
        width: 120,
      },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        renderCell: (params) => (
          <>
            <WarehouseStatusIcon warehouseStatus={params.row.status} />
            <Box ml={1}>{getWarehouseStatusTranslation(params.row.status)}</Box>
          </>
        ),
      },
      {
        field: "isAutoAssignmentEnabled",
        headerName: "Auto Assign",
        width: 120,
        renderCell: (params) => (
          <>
            <CircleIcon
              sx={{
                color: params.row.isAutoAssignmentEnabled
                  ? COLOR.green[500]
                  : COLOR.red[500],
                fontSize: 10,
              }}
            />
            <Box ml={1}>
              {params.row.isAutoAssignmentEnabled ? "On" : "Off"}
            </Box>
          </>
        ),
      },
      {
        field: "thirdPartyProviders",
        headerName: "3P",
        width: 100,
        hide:
          !hasPermission("UPDATE_WAREHOUSE_THIRD_PARTY_PROVIDERS") ||
          (!isFeatureEnabled("STUART") && !isFeatureEnabled("UBER")),
        renderCell: (params) =>
          params.row.thirdPartyProviders
            .filter(isFeatureEnabled)
            .map((provider) => (
              <Box key={provider} p={0.4}>
                <img
                  src={getThirdPartyProviderImageSrc(provider)}
                  alt={getThirdPartyProviderTranslation(provider)}
                  height={28}
                />
              </Box>
            )),
      },
      {
        field: "riders",
        headerName: "Riders",
        width: 120,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) =>
          riders.filter((r) => r.assignedWarehouseId === params.row.id)
            .length || 0,
        renderCell: (params) => `${params.formattedValue} Riders`,
      },
      {
        field: "ridersOnline",
        headerName: "On Duty",
        width: 120,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) =>
          riders.filter(
            (r) =>
              r.isOnDuty === true && r.assignedWarehouseId === params.row.id
          ).length || 0,
        renderCell: (params) => `${params.formattedValue} On Duty`,
      },
      {
        field: "dispatchers",
        headerName: "Dispatchers",
        width: 120,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) =>
          users.filter(
            (u) =>
              u.role === "DISPATCHER" && u.warehouseIds?.includes(params.row.id)
          ).length || 0,
        renderCell: (params) => `${params.formattedValue} Dispatchers`,
      },
      {
        field: "actions",
        headerName: "",
        width: 120,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        hide: !hasPermission("UPDATE_WAREHOUSE"),
        valueGetter: (params) => params.row.id,
        renderCell: (params) => (
          <Box className="row-action">
            <IconButton
              component={Link}
              to={`/settings/stores/${params.row.id}`}
              onClick={() => {}}
            >
              <EditOutlinedIcon color="secondary" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [hasPermission, isFeatureEnabled, riders, users]
  );

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <SettingsTitleView>Stores</SettingsTitleView>
      {isLoading && <LoadingView />}
      {error && (
        <Alert sx={{ mt: 2 }} severity="error">
          {error.message}
        </Alert>
      )}
      {isLoaded && (
        <DataGridPaper>
          <DataGrid
            columns={columns}
            rows={warehouses}
            getRowId={(warehouse) => warehouse.id}
            disableColumnSelector
            disableSelectionOnClick
          />
        </DataGridPaper>
      )}
      <Outlet />
    </Box>
  );
};
