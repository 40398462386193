import { RiderStatus, TaskStatus } from "@quickcommerceltd/zephyr-types";
import create from "zustand";
import { DEFAULT_FEATURE_FLAGS } from "../../Settings/constants/DEFAULT_FEATURE_FLAGS";
import { FeatureFlags } from "../../Settings/types/FeatureFlags";

const LOCAL_STORAGE_KEY = "ZEPHYR";

export const DEFAULT_LOCAL_STORAGE: LocalStorage = {
  filteredRiderStatuses: [
    "PICKING_UP",
    "IDLE_AT_STORE",
    "WAITING_FOR_PACKED_ORDER",
    "DELIVERING",
    "IDLE_AT_CUSTOMER",
    "RIDING_BACK_TO_STORE",
  ],
  filteredTaskStatuses: [
    "UNASSIGNED",
    "PENDING_RIDER_ASSIGNMENT",
    "ASSIGNED",
    "DELIVERING",
  ],
  featureFlags: DEFAULT_FEATURE_FLAGS,
};

interface LocalStorage {
  selectedWarehouseId?: string;
  filteredTaskStatuses?: TaskStatus[];
  filteredRiderStatuses?: RiderStatus[];
  featureFlags?: FeatureFlags;
}

interface LocalStorageStore {
  data: Partial<LocalStorage>;
  set: (next: Partial<LocalStorage>) => void;
  initialize: () => void;
}

export const useLocalStorage = create<LocalStorageStore>((set, get) => ({
  data: {},
  set: (next) => {
    const data = {
      ...get().data,
      ...next,
    };

    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
    set({ data });
  },
  initialize: () => {
    const dataJson = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    const data: LocalStorage = dataJson
      ? JSON.parse(dataJson)
      : DEFAULT_LOCAL_STORAGE;

    fixLocalStorage(data);
    set({ data });
  },
}));

useLocalStorage.getState().initialize();

/**
 * @depreacted
 * TODO: Remove on 15th September 2022
 */
function fixLocalStorage(data: LocalStorage) {
  let hasBeenFixed = false;

  if (
    data.filteredRiderStatuses &&
    data.filteredRiderStatuses.includes("RIDING_BACK_TO_STORE") &&
    !data.filteredRiderStatuses.includes("PICKING_UP")
  ) {
    data.filteredRiderStatuses.push("PICKING_UP");
    hasBeenFixed = true;
  }

  if (
    data.filteredTaskStatuses &&
    data.filteredTaskStatuses.includes("ASSIGNED") &&
    !data.filteredTaskStatuses.includes("PENDING_RIDER_ASSIGNMENT")
  ) {
    data.filteredTaskStatuses.push("PENDING_RIDER_ASSIGNMENT");
    hasBeenFixed = true;
  }

  if (hasBeenFixed) {
    useLocalStorage.getState().set(data);
  }
}
