import { useMemo } from "react";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { useRiders } from "./useRiders";

export function useFilteredRiders() {
  const riders = useRiders((state) => state.riders);
  const riderStatuses = useLocalStorage(
    (state) => state.data.filteredRiderStatuses
  );

  return useMemo(() => {
    if (riderStatuses) {
      return riders.filter((r) => riderStatuses.includes(r.status));
    } else {
      return riders;
    }
  }, [riderStatuses, riders]);
}
