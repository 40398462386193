import { RiderStatus, TaskStatus } from "@quickcommerceltd/zephyr-types";
import { isTaskStatusStacked } from "./isTaskStatusStacked";

export function getTaskStatusMarkerUrl(
  taskStatus: TaskStatus,
  riderStatus?: RiderStatus
): string {
  switch (taskStatus) {
    case "UNASSIGNED":
      return "/assets/pin-task-unassigned.svg";
    case "PENDING_RIDER_ASSIGNMENT":
      return "/assets/pin-task-assigned.svg";
    case "ASSIGNED":
      return isTaskStatusStacked(taskStatus, riderStatus)
        ? "/assets/pin-task-stacked.svg"
        : "/assets/pin-task-assigned.svg";
    case "DELIVERING":
      return "/assets/pin-task-delivering.svg";
    case "DELIVERED":
      return "/assets/pin-task-delivered.svg";
    case "FAILED":
      return "/assets/pin-task-failed.svg";
  }
}
