import { Timestamp } from "@quickcommerceltd/zephyr-types";
import { DateTime } from "luxon";

export function getDiffInSeconds(
  timestamp: Timestamp,
  shouldBeInThePast: boolean
) {
  const seconds = Math.floor(
    DateTime.fromMillis(timestamp.toMillis()).diffNow("seconds").seconds
  );

  return shouldBeInThePast
    ? Math.abs(Math.min(seconds, 0))
    : Math.max(seconds, 0);
}
