import { TaskStatus } from "@quickcommerceltd/zephyr-types";

export const TASK_STATUSES: TaskStatus[] = [
  "UNASSIGNED",
  "PENDING_RIDER_ASSIGNMENT",
  "ASSIGNED",
  "DELIVERING",
  "DELIVERED",
  "FAILED",
];
