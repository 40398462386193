import { Rider, Task } from "@quickcommerceltd/zephyr-types";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { assignTaskCallable } from "../../Auth/callables/task/assignTaskCallable";
import { logError } from "../../Common/helpers/logError";

export function useAssignTask(): [
  (task: Task, rider: Rider) => Promise<void>,
  boolean
] {
  const [isAssigningTask, setIsAssigningTask] = useState(false);

  const assignTask = useCallback(async (task: Task, rider: Rider) => {
    try {
      setIsAssigningTask(true);
      await assignTaskCallable({
        taskId: task.id,
        riderId: rider.id,
      });
    } catch (error: any) {
      toast.error(error.message);
      logError(error);
    } finally {
      setIsAssigningTask(false);
    }
  }, []);

  return [assignTask, isAssigningTask];
}
