import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import MopedOutlinedIcon from "@mui/icons-material/MopedOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import { Box, IconButton, List, Paper, styled } from "@mui/material";
import { FC } from "react";
import { AppThemeProvider } from "../../App/components/AppThemeProvider";
import { COLOR } from "../../App/constants/COLOR";
import { DotView } from "../../Common/components/DotView";
import { EscapeKeyToClose } from "../../Common/components/EscapeKeyToClose";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { useFilter } from "../hooks/useFilter";
import { useRiderFilterLabel } from "../hooks/useRiderFilterLabel";
import { useTaskFilterLabel } from "../hooks/useTaskFilterLabel";
import { FilterButton } from "./FilterButton";
import { FilterKindListItem } from "./FilterKindListItem";
import { FilterStatusView } from "./FilterStatusView";
import { FilterWarehouseView } from "./FilterWarehouseView";

export const FilterMainView: FC = () => {
  const filter = useFilter();
  const selectedWarehouse = useSelectedWarehouse();
  const taskFilterLabel = useTaskFilterLabel();
  const riderFilterLabel = useRiderFilterLabel();

  return (
    <AppThemeProvider mode="light">
      <SPaper
        sx={{ bgcolor: filter.filterKind ? "white" : "transparent" }}
        data-testid="FilterMainViewRoot"
      >
        <Box display="flex" alignItems="flex-end" pr={2}>
          <FilterButton
            label={selectedWarehouse?.name || "All Stores"}
            filterKind="warehouse"
            iconComponent={WarehouseOutlinedIcon}
            testId="WarehouseFilterButton"
            data-testid="WarehouseFilterButton"
          />
          <FilterButton
            label={taskFilterLabel}
            filterKind="status"
            iconComponent={FmdGoodOutlinedIcon}
            testId="TaskFilterButton"
          />
          <FilterButton
            label={riderFilterLabel}
            filterKind="status"
            iconComponent={MopedOutlinedIcon}
            testId="RiderFilterButton"
          />
          {filter.filterKind && (
            <Box display="flex" flex={1} justifyContent="flex-end">
              <IconButton
                color="secondary"
                onClick={() => filter.setFilterKind(undefined)}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {filter.filterKind && (
          <Box display="flex" mt={2} borderTop={`1px solid ${COLOR.gray[50]}`}>
            <EscapeKeyToClose close={() => filter.setFilterKind(undefined)} />
            <Box width={290} borderRight={`1px solid ${COLOR.gray[50]}`}>
              <List>
                <FilterKindListItem
                  filterKind="warehouse"
                  title="Store"
                  iconComponent={WarehouseOutlinedIcon}
                >
                  {selectedWarehouse?.name || "All"}
                </FilterKindListItem>
                <FilterKindListItem
                  filterKind="status"
                  title="Status"
                  iconComponent={FmdGoodOutlinedIcon}
                >
                  {taskFilterLabel}
                  <DotView />
                  {riderFilterLabel}
                </FilterKindListItem>
              </List>
            </Box>
            <Box minWidth={450}>
              {filter.filterKind === "warehouse" && <FilterWarehouseView />}
              {filter.filterKind === "status" && <FilterStatusView />}
            </Box>
          </Box>
        )}
      </SPaper>
    </AppThemeProvider>
  );
};

const SPaper = styled(Paper)({
  boxShadow: "none",
  position: "absolute",
  top: 0,
  left: 0,
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
});
