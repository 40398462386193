import { useMemo } from "react";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { useTasks } from "../../Task/hooks/useTasks";

export function useFilteredTasks() {
  const tasks = useTasks((state) => state.tasks);
  const localStorage = useLocalStorage();

  return useMemo(() => {
    const statuses = localStorage.data.filteredTaskStatuses;

    if (statuses) {
      return tasks.filter((r) => statuses.includes(r.status));
    } else {
      return tasks;
    }
  }, [localStorage.data.filteredTaskStatuses, tasks]);
}
