export const AVG_DAYS_IN_MONTH = 30.437;

export function getDiffTranslation(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(seconds / 60 / 60);
  const days = Math.floor(seconds / 60 / 60 / 24);
  const months = Math.floor(seconds / 60 / 60 / 24 / AVG_DAYS_IN_MONTH);

  if (months > 1) {
    return `${months} months`;
  } else if (months === 1) {
    return `1 month`;
  } else if (days > 1) {
    return `${days} days`;
  } else if (days === 1) {
    return `1 day`;
  } else if (hours > 1) {
    return `${hours} hrs`;
  } else if (hours === 1) {
    return `1 hr`;
  } else if (minutes > 1) {
    return `${minutes} mins`;
  } else if (minutes === 1) {
    return `1 min`;
  } else if (seconds > 1) {
    return `${seconds} secs`;
  } else if (seconds === 1) {
    return `1 sec`;
  } else {
    return "0 secs";
  }
}
