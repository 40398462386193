import { ThirdPartyProvider } from "@quickcommerceltd/zephyr-types";
import { useMemo } from "react";
import { useIsFeatureEnabled } from "../../Settings/hooks/useIsFeatureEnabled";

export function useVisibleThirdPartyProviders() {
  const isFeatureEnabled = useIsFeatureEnabled();

  return useMemo(() => {
    const providers: ThirdPartyProvider[] = [];

    if (isFeatureEnabled("STUART")) {
      providers.push("STUART");
    }
    if (isFeatureEnabled("UBER")) {
      providers.push("UBER");
    }

    return providers;
  }, [isFeatureEnabled]);
}
