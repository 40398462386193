import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Divider, List } from "@mui/material";
import { FC, useMemo } from "react";
import { COLOR } from "../../App/constants/COLOR";
import { ZAccordion } from "../../Common/components/ZAccordion";
import { getDistanceInMiles } from "../../Map/helpers/getDistance";
import { useRiders } from "../../Rider/hooks/useRiders";
import { useSelectedWarehouse } from "../../Warehouse/hooks/useSelectedWarehouse";
import { SidebarEmptyListItem } from "./SidebarEmptyListItem";
import { SidebarRiderListItem } from "./SidebarRiderListItem";

export const SidebarInTransitView: FC = () => {
  const selectedWarehouse = useSelectedWarehouse();
  const riders = useRiders((state) => state.riders);
  const inTransitRiders = useMemo(
    () =>
      riders
        .filter((r) => ["DELIVERING", "IDLE_AT_CUSTOMER"].includes(r.status))
        .sort(
          (a, b) =>
            (a.leftStoreAt?.toMillis() || 0) - (b.leftStoreAt?.toMillis() || 0)
        ),
    [riders]
  );
  const ridingBackToStoreRiders = useMemo(
    () =>
      riders
        .filter((r) => r.status === "RIDING_BACK_TO_STORE")
        .sort((a, b) =>
          selectedWarehouse
            ? (a.coords
                ? getDistanceInMiles(a.coords, selectedWarehouse.address)
                : 0) -
              (b.coords
                ? getDistanceInMiles(b.coords, selectedWarehouse.address)
                : 0)
            : 0
        ),
    [riders, selectedWarehouse]
  );

  return (
    <ZAccordion
      title="In Transit"
      titleIcon={<LocationOnIcon />}
      testId="SidebarInTransitAccordian"
    >
      <List>
        {inTransitRiders.length === 0 &&
          ridingBackToStoreRiders.length === 0 && (
            <SidebarEmptyListItem
              message="No riders in transit"
              iconComponent={CheckCircleOutlineIcon}
              color={COLOR.green[500]}
            />
          )}
        {inTransitRiders.map((rider) => (
          <SidebarRiderListItem key={rider.id} rider={rider} />
        ))}
        {inTransitRiders.length > 0 && ridingBackToStoreRiders.length > 0 && (
          <Divider sx={{ my: 1 }} />
        )}
        {ridingBackToStoreRiders.map((rider) => (
          <SidebarRiderListItem key={rider.id} rider={rider} />
        ))}
      </List>
    </ZAccordion>
  );
};
