import { DispatcherUpdateTaskRequest } from "@quickcommerceltd/zephyr-types";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { updateTaskCallable } from "../../Auth/callables/task/updateTaskCallable";
import { logError } from "../../Common/helpers/logError";

export function useEditTask() {
  const [isEditingTask, setIsEditingTask] = useState(false);

  const editTask = useCallback(async (params: DispatcherUpdateTaskRequest) => {
    try {
      setIsEditingTask(true);

      await updateTaskCallable(params);
    } catch (error: any) {
      toast.error(error.message);
      logError(error);
    } finally {
      setIsEditingTask(false);
    }
  }, []);

  return useMemo(
    () => ({
      editTask,
      isEditingTask,
    }),
    [editTask, isEditingTask]
  );
}
