import { ListItemButton, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { COLOR } from "../../App/constants/COLOR";

interface Props {
  to: string;
  title: string;
  iconComponent: OverridableComponent<SvgIconTypeMap>;
}

export const SettingsNavLink: FC<Props> = (props) => {
  return (
    <ListItemButton
      component={NavLink}
      to={props.to}
      sx={{
        py: 1.5,
        fontSize: 16,
        fontWeight: 400,
        "&.active": {
          fontWeight: 700,
          bgcolor: COLOR.blue[10],
          "&:hover": {
            bgcolor: COLOR.blue[50],
          },
        },
      }}
    >
      <props.iconComponent sx={{ color: COLOR.blue[900], mr: 2 }} />
      {props.title}
    </ListItemButton>
  );
};
