export function isVersionSupported(minVersion: string, currentVersion: string) {
  const splittedMinVersion = minVersion.split(".");
  const splittedCurrentVersion = currentVersion.split(".");

  for (let i = 0; i < splittedMinVersion.length; i++) {
    const minVersionNumber = parseInt(splittedMinVersion[i]);
    const currentVersionNumber = parseInt(splittedCurrentVersion[i]) || 0;

    if (currentVersionNumber !== minVersionNumber) {
      return currentVersionNumber >= minVersionNumber;
    }
  }

  return true;
}
