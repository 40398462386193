import { RiderStatus, TaskStatus } from "@quickcommerceltd/zephyr-types";

export function isTaskStatusStacked(
  taskStatus: TaskStatus,
  riderStatus?: RiderStatus
) {
  return (
    taskStatus === "ASSIGNED" &&
    riderStatus &&
    ["DELIVERING", "IDLE_AT_CUSTOMER"].includes(riderStatus)
  );
}
