import { DispatcherCreateTaskRequest } from "@quickcommerceltd/zephyr-types";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createTaskCallable } from "../../Auth/callables/task/createTaskCallable";
import { logError } from "../../Common/helpers/logError";

export function useCreateTask() {
  const navigate = useNavigate();
  const [isCreatingTask, setIsCreatingTask] = useState(false);

  const createTask = useCallback(
    async (params: DispatcherCreateTaskRequest) => {
      try {
        setIsCreatingTask(true);

        const task = await createTaskCallable({
          externalOrderNumber: params.externalOrderNumber,
          customer: params.customer,
          address: params.address,
          noteFromCustomer: params.noteFromCustomer,
          warehouseId: params.warehouseId,
        });

        navigate(`/tasks/${task.id}`);
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      } finally {
        setIsCreatingTask(false);
      }
    },
    [navigate]
  );

  return useMemo(
    () => ({
      createTask,
      isCreatingTask,
    }),
    [createTask, isCreatingTask]
  );
}
