import { Box, styled, Typography } from "@mui/material";
import { Task } from "@quickcommerceltd/zephyr-types";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { COLOR } from "../../App/constants/COLOR";

interface Props {
  task: Task;
}

export const TaskOnTimeView: FC<Props> = (props) => {
  const [, setRerender] = useState(0);
  const promisedDeliveryTime = props.task.promisedDeliveryTimeInMinutes
    ? DateTime.fromMillis(props.task.createdAt.toMillis()).plus({
        minutes: props.task.promisedDeliveryTimeInMinutes,
      })
    : undefined;
  const estimatedDeliveryTime = props.task.estimatedDeliveryAt
    ? DateTime.fromMillis(props.task.estimatedDeliveryAt?.toMillis())
    : undefined;
  const now = DateTime.local();

  useEffect(() => {
    const interval = setInterval(() => setRerender((v) => v + 1), 10 * 1000);

    return () => clearInterval(interval);
  }, []);

  if (!promisedDeliveryTime) return null;
  if (!estimatedDeliveryTime) return null;

  const isOnTime = promisedDeliveryTime > estimatedDeliveryTime;
  const isIsDelayed = promisedDeliveryTime < now;

  return (
    <SBox
      sx={{
        bgcolor: isOnTime
          ? COLOR.green[500]
          : isIsDelayed
          ? COLOR.red[500]
          : COLOR.orange[500],
      }}
    >
      <Typography fontSize={14} fontWeight={700}>
        {isOnTime ? "on time" : isIsDelayed ? "delayed" : "forcasted delay"}
      </Typography>
    </SBox>
  );
};

const SBox = styled(Box)`
  padding: 4px 8px;
  border-radius: 6px;
  text-transform: uppercase;
`;
