import { Collapse, ListItem, Typography } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { ZChevron } from "../../Common/components/ZChevron";

interface Props {
  title: string;
  children: ReactNode;
}

export const ZSecondaryAccordion: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ZChevron isOpen={isOpen} />
        <Typography ml={1} fontWeight={500}>
          {props.title}
        </Typography>
      </ListItem>
      <Collapse in={isOpen}>{props.children}</Collapse>
    </>
  );
};
