import { Task, TaskStatus } from "@quickcommerceltd/zephyr-types";
import { limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { DateTime } from "luxon";
import toast from "react-hot-toast";
import create from "zustand";
import { getTaskCollectionRef } from "../../Auth/helpers/getCollectionRef";
import { getTasksFromSnapshot } from "../../Auth/helpers/getDataFromQuerySnapshot";
import { TASK_COMPLETE_STATUSES } from "../constants/TASK_COMPLETE_STATUSES";

const UNCOMPLETED_TASK_STATUSES: TaskStatus[] = [
  "UNASSIGNED",
  "PENDING_RIDER_ASSIGNMENT",
  "ASSIGNED",
  "DELIVERING",
];

export interface TasksStore {
  tasks: Task[];
  subscribe: (warehouseId: string) => void;
  unsubscribe: () => void;
  unsubscribeFirestore?: () => void;
}

export const useTasks = create<TasksStore>((set, get) => ({
  tasks: [],
  subscribe: (warehouseId) => {
    let uncompletedTasks: Task[] = [];
    let completedTasks: Task[] = [];

    const unsubscribeUncompletedTasks = onSnapshot(
      query(
        getTaskCollectionRef(),
        where("warehouseId", "==", warehouseId),
        where("status", "in", UNCOMPLETED_TASK_STATUSES)
      ),
      async (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;
        uncompletedTasks = getTasksFromSnapshot(snapshot);

        set({ tasks: [...uncompletedTasks, ...completedTasks] });
      },
      (error: any) => {
        toast.error(error.message);
        set({ tasks: [] });
      }
    );

    const unsubscribeCompletedTasks = onSnapshot(
      query(
        getTaskCollectionRef(),
        where("warehouseId", "==", warehouseId),
        where("status", "in", TASK_COMPLETE_STATUSES),
        where(
          "completedAt",
          ">",
          DateTime.utc().minus({ hours: 2 }).toJSDate()
        ),
        orderBy("completedAt", "desc"),
        limit(15)
      ),
      async (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;
        completedTasks = getTasksFromSnapshot(snapshot);

        set({ tasks: [...uncompletedTasks, ...completedTasks] });
      },
      (error: any) => {
        toast.error(error.message);
        set({ tasks: [] });
      }
    );

    set({
      unsubscribeFirestore: () => {
        unsubscribeUncompletedTasks();
        unsubscribeCompletedTasks();
      },
    });
  },
  unsubscribe: () => {
    set({ tasks: [] });
    get().unsubscribeFirestore?.();
  },
}));
