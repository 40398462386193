import { ThirdPartyProvider } from "@quickcommerceltd/zephyr-types";

export function getThirdPartyProviderTranslation(
  thirdPartyProvider: ThirdPartyProvider
): string {
  switch (thirdPartyProvider) {
    case "STUART":
      return "Stuart";
    case "UBER":
      return "Uber";
  }
}
