import { alpha, keyframes, ListItem, styled } from "@mui/material";
import { ForwardRefExoticComponent } from "react";
import { Link, LinkProps } from "react-router-dom";
import { COLOR } from "../../App/constants/COLOR";

interface Props extends LinkProps {
  disableRipple?: true;
  button?: true;
  component?: ForwardRefExoticComponent<LinkProps>;
}

export const SidebarListItem = styled(ListItem)<Props>(() => ({
  "&.warning-priority-2": {
    backgroundColor: alpha(COLOR.orange[500], 0.25),
    "&:hover": {
      backgroundColor: alpha(COLOR.orange[500], 0.25),
    },
  },
  "&.warning-priority-3": {
    animation: `${WARNING_KEYFRAMES} 1s infinite`,
  },
  "&.warning-priority-2, &.warning-priority-3": {
    "&:hover::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: alpha(COLOR.white[0], 0.08),
    },
  },
}));

SidebarListItem.defaultProps = {
  disableRipple: true,
  button: true as any, // using as any because of an deprecated MUI bug.
  component: Link,
};

export const WARNING_KEYFRAMES = keyframes`
  from {
    background-color: transparent;
  }
  to {
    background-color: ${alpha(COLOR.orange[500], 0.25)};
  }
`;
