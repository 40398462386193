import { PlatformOs } from "@quickcommerceltd/zephyr-types";

export function getPlatformOsTranslation(platformOs?: PlatformOs) {
  switch (platformOs) {
    case "ios":
      return "iOS";
    case "android":
      return "Android";
    default:
      return "Not set";
  }
}
