import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import googleLogo from "../../Assets/images/google-logo.svg";
import zephyrLogo from "../../Assets/images/zephyr-logo.svg";
import { loginWithGoogle } from "../helpers/loginWithGoogle";

export const AuthLoginView: FC = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Box>
        <Box display="flex" justifyContent="center">
          <img
            src={zephyrLogo}
            alt="Zephyr"
            height="50"
            data-testid="LoginZephyrLogo"
          />
        </Box>
        <Typography
          variant="h5"
          my={3}
          align="center"
          data-testid="LoginPageHeading"
        >
          Sign in to your account
        </Typography>
        <Button
          variant="contained"
          color="inherit"
          fullWidth
          onClick={loginWithGoogle}
          data-testid="SignInButton"
        >
          <img src={googleLogo} alt="Google" height="30" color="white" />
        </Button>
      </Box>
    </Box>
  );
};
