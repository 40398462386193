import { CsvRow } from "../types/CsvRow";

export function exportCsv(csvRows: CsvRow[], fileName: string) {
  const csvContent = `data:text/csv;charset=utf-8,${csvRows
    .map((row) => row.join(","))
    .join("\n")}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");

  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();
  link.remove();
}
