import { Task, Warehouse } from "@quickcommerceltd/zephyr-types";

export function getTaskPickUpTooltip(task: Task, warehouse?: Warehouse) {
  if (!warehouse?.thirdPartyProviders.includes("STUART")) {
    return;
  }

  if (task.estimatedPickUpDurationInSeconds?.STUART === undefined) {
    return;
  }

  if (!["UNASSIGNED", "PENDING_RIDER_ASSIGNMENT"].includes(task.status)) {
    return;
  }

  return `Stuart: ${Math.ceil(
    task.estimatedPickUpDurationInSeconds.STUART / 60
  )} mins`;
}
