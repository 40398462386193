export function getPolylineArrows(opcity: number) {
  return [
    {
      icon: {
        path: "M 0,-4 -0.8,-2.5 0.8,-2.5 0,-4 0,1",
        strokeOpacity: opcity,
        scale: 2,
      },
      offset: "0",
      repeat: "18px",
    },
  ];
}
