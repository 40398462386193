import { UserRole } from "@quickcommerceltd/zephyr-types";

export function getUserRoleTranslation(userRole?: UserRole) {
  switch (userRole) {
    case "ADMIN":
      return "Admin";
    case "LIVE_OPS":
      return "Live Ops";
    case "DISPATCHER":
      return "Dispatcher";
    case "CUSTOMER_SERVICE":
      return "Customer Service";
    default:
      return "Unknown Role";
  }
}
