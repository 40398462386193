import { RiderStatus, TaskStatus } from "@quickcommerceltd/zephyr-types";

export function hasTaskLeftTheStore(
  taskStatus: TaskStatus,
  riderStatus?: RiderStatus
) {
  if (taskStatus === "UNASSIGNED") return false;
  if (taskStatus === "PENDING_RIDER_ASSIGNMENT") return false;
  if (taskStatus === "ASSIGNED") {
    if (!riderStatus) return false;

    return ["DELIVERING", "IDLE_AT_CUSTOMER"].includes(riderStatus);
  }

  return true;
}
