import * as Sentry from "@sentry/react";
import { ENVIRONMENT } from "../../App/constants/ENVIRONMENT";

export function logError(error: Error) {
  if (ENVIRONMENT.nodeEnv === "production") {
    try {
      Sentry.captureException(error);
    } catch {}
  }

  return console.warn(error);
}
