import { Avatar, SxProps, Theme } from "@mui/material";
import { FC } from "react";

/**
 * Credits: https://mui.com/material-ui/react-avatar/
 */
function getAvatarAbbreviation(name?: string | null) {
  if (!name) return undefined;

  const parts = name.trim().split(" ");
  const letter1 = parts[0][0];
  const letter2 = parts[1]?.[0] || "";

  return `${letter1}${letter2}`;
}

const AVATAR_SIZE = 36;

interface Props {
  name?: string | null;
  src?: string | null;
  alt?: string;
  sx?: SxProps<Theme>;
}

export const ZAvatar: FC<Props> = (props) => {
  return (
    <Avatar
      src={props.src || undefined}
      alt={props.alt}
      sx={{
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
        fontSize: 12,
        fontWeight: 700,
        ...props.sx,
      }}
    >
      {getAvatarAbbreviation(props.name)}
    </Avatar>
  );
};
