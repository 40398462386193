import { FormControl, SxProps, TextField, Theme } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Control, Controller, FieldPath } from "react-hook-form";
import { DateTime } from "luxon";

const DATE_TIME_FORMAT = "dd MMM yyyy, hh.mm a";

interface Props<T> {
  control: Control<T>;
  name: FieldPath<T>;
  label: string;
  sx?: SxProps<Theme>;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export function ControlledDateTimePicker<T>(props: Props<T>) {
  return (
    <FormControl fullWidth={props.fullWidth} sx={props.sx}>
      <Controller
        name={props.name}
        control={props.control}
        rules={{ required: props.required ? "Required" : false }}
        render={({ field, fieldState }) => (
          <DateTimePicker
            {...field}
            inputFormat={DATE_TIME_FORMAT}
            label={props.label}
            disabled={props.disabled}
            onChange={(value) => field.onChange((value as DateTime).toJSDate())}
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                size="small"
                required={props.required}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </FormControl>
  );
}
