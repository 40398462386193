import { alpha, Box, Typography } from "@mui/material";
import { ThirdPartyProvider } from "@quickcommerceltd/zephyr-types";
import { FC, useMemo } from "react";
import { COLOR } from "../../App/constants/COLOR";
import { getThirdPartyProviderTranslation } from "../../Common/helpers/getThirdPartyProviderTranslation";
import { useMap } from "../../Map/hooks/useMap";
import { RiderStatusIcon } from "../../Rider/components/RiderStatusIcon";
import { useDropThirdPartyProvider } from "../../Rider/hooks/useDropThirdPartyProvider";
import { useHoverRider } from "../../Rider/hooks/useHoverRider";
import { useTasks } from "../../Task/hooks/useTasks";
import { SidebarListItem } from "./SidebarListItem";
import { SidebarTaskListItemWithTooltip } from "./SidebarTaskListItemWithTooltip";

interface Props {
  thirdPartyProvider: ThirdPartyProvider;
}

export const SidebarThirdPartyProviderListItem: FC<Props> = (props) => {
  const [{ isOver, error }, drop] = useDropThirdPartyProvider(
    props.thirdPartyProvider
  );
  const { onMouseOut, onMouseOver } = useHoverRider([], "THIRD_PARTY_PROVIDER");
  const isMapTaskFocused = useMap((store) => !!store.focusedMapTaskId);
  const tasks = useTasks((store) => store.tasks);
  const pendingTasks = useMemo(
    () =>
      tasks
        .filter(
          (task) =>
            task.status === "PENDING_RIDER_ASSIGNMENT" &&
            task.thirdPartyProvider === props.thirdPartyProvider
        )
        .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()),
    [props.thirdPartyProvider, tasks]
  );

  return (
    <>
      <SidebarListItem
        ref={drop}
        sx={{
          opacity: isMapTaskFocused ? 0.1 : 1,
          ...(isOver && {
            backgroundColor: alpha(
              error ? COLOR.red[500] : COLOR.green[500],
              0.5
            ),
          }),
        }}
        to={`/settings/stores`}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <RiderStatusIcon riderStatus="IDLE_AT_STORE" />
        <Box ml={1}>
          <Typography fontSize={12} fontWeight={700}>
            3P - {getThirdPartyProviderTranslation(props.thirdPartyProvider)}
          </Typography>
          <Typography fontSize={12} fontWeight={500} sx={{ opacity: 0.75 }}>
            Assign task here for{" "}
            {getThirdPartyProviderTranslation(props.thirdPartyProvider)}{" "}
            fulfillment
          </Typography>
        </Box>
      </SidebarListItem>
      {pendingTasks.map((task) => (
        <SidebarTaskListItemWithTooltip key={task.id} task={task} pl={3} />
      ))}
    </>
  );
};
