import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import StarRateIcon from "@mui/icons-material/StarRate";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import { RiderStatus, Task, TaskStatus } from "@quickcommerceltd/zephyr-types";
import { FC } from "react";
import { COLOR } from "../../App/constants/COLOR";
import { LoadingIcon } from "../../Common/components/LoadingIcon";
import { isTaskStatusStacked } from "../helpers/isTaskStatusStacked";

const DEFAULT_ICON_SIZE = 20;

interface Props {
  task: Partial<Task> & { status: TaskStatus };
  riderStatus?: RiderStatus;
  size?: number;
  isLoading?: boolean;
}

export const TaskStatusIcon: FC<Props> = (props) => {
  const fontSize = props.size || DEFAULT_ICON_SIZE;
  const isStacked = isTaskStatusStacked(props.task.status, props.riderStatus);

  if (props.isLoading) {
    return <LoadingIcon size={fontSize} />;
  }

  if (props.task.hasPriority) {
    if (props.task.status === "UNASSIGNED") {
      return <StarRateIcon sx={{ color: COLOR.gray[200], fontSize }} />;
    }

    if (
      props.task.status === "PENDING_RIDER_ASSIGNMENT" ||
      (props.task.status === "ASSIGNED" && !isStacked)
    ) {
      return <StarRateIcon sx={{ color: COLOR.yellow[500], fontSize }} />;
    }
  }

  switch (props.task.status) {
    case "UNASSIGNED":
      return <LocationOnIcon sx={{ color: COLOR.gray[200], fontSize }} />;
    case "PENDING_RIDER_ASSIGNMENT":
      return <HourglassTopIcon sx={{ color: COLOR.yellow[500], fontSize }} />;
    case "ASSIGNED":
      return (
        <PersonPinCircleIcon
          sx={{
            fontSize,
            color: isStacked ? COLOR.blue[500] : COLOR.yellow[500],
          }}
        />
      );
    case "DELIVERING":
      return <PersonPinCircleIcon sx={{ color: COLOR.blue[500], fontSize }} />;
    case "DELIVERED":
      return <WhereToVoteIcon sx={{ color: COLOR.green[500], fontSize }} />;
    case "FAILED":
      return <LocationOnIcon sx={{ color: COLOR.red[500], fontSize }} />;
  }
};
