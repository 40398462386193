import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { FC, useMemo } from "react";
import { AppThemeProvider } from "../../App/components/AppThemeProvider";
import { deepReplaceTimestamp } from "../helpers/deepReplaceTimestamp";
import { Log } from "../types/Log";

interface Props {
  title: string;
  log?: Log;
  isOpen: boolean;
  close: () => void;
}

export const LogDialog: FC<Props> = (props) => {
  const logJsonString = useMemo(
    () => JSON.stringify(deepReplaceTimestamp(props.log?.update), null, 4),
    [props.log]
  );

  if (!props.log) return null;

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <span>{props.title}</span>
          <Typography>
            {DateTime.fromMillis(props.log.createdAt.toMillis()).toLocaleString(
              DateTime.DATETIME_FULL_WITH_SECONDS
            )}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={1} direction="row">
            <Chip
              label={`${props.log.updatedBy.type}: ${props.log.updatedBy.name}`}
            />
            <Chip label={`ID: ${props.log.updatedBy.id}`} />
          </Stack>
          <pre>{logJsonString}</pre>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="contained" onClick={props.close} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  );
};
