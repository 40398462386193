import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { Task, Warehouse } from "@quickcommerceltd/zephyr-types";
import { deleteField, serverTimestamp, updateDoc } from "firebase/firestore";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { AppThemeProvider } from "../../App/components/AppThemeProvider";
import { getTaskDocRef } from "../../Auth/helpers/getDocRef";
import { logError } from "../../Common/helpers/logError";
import { useWarehouseFromWarehouseId } from "../../Warehouse/hooks/useWarehouseFromWarehouseId";
import { useUnassignTask } from "../hooks/useUnassignTask";

interface Props {
  task: Task;
  nextWarehouse?: Warehouse;
  isOpen: boolean;
  close: () => void;
}

export const TaskAssignWarehouseDialog: FC<Props> = (props) => {
  const [unassignTask] = useUnassignTask();
  const [isLoading, setIsLoading] = useState(false);
  const previousWarehouse = useWarehouseFromWarehouseId(props.task.warehouseId);

  const onClick = useCallback(async () => {
    try {
      setIsLoading(true);

      if (props.task.riderId) {
        await unassignTask(props.task);
      }

      await updateDoc(getTaskDocRef(props.task.id), {
        warehouseId: props.nextWarehouse?.id || deleteField(),
        updatedAt: serverTimestamp(),
      });

      props.close();
    } catch (error: any) {
      toast.error(error.message);
      logError(error);
    } finally {
      setIsLoading(false);
    }
  }, [props, unassignTask]);

  return (
    <AppThemeProvider mode="light">
      <Dialog open={props.isOpen} onClose={props.close} fullWidth>
        <DialogTitle>
          {previousWarehouse ? "Reassign Task" : "Assign Task"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            Are you sure you want to assign the task from{" "}
            <strong>{previousWarehouse?.name || "unassigned"}</strong> to{" "}
            <strong>{props.nextWarehouse?.name || "none"}</strong>?
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <LoadingButton
            autoFocus
            variant="contained"
            loading={isLoading}
            onClick={onClick}
          >
            Reassign
          </LoadingButton>
          <Button onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </AppThemeProvider>
  );
};
