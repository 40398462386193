import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import { SxProps } from "@mui/material";
import { PlatformOs } from "@quickcommerceltd/zephyr-types";
import { FC } from "react";

interface Props {
  sx: SxProps;
  platformOs?: PlatformOs;
}

export const PlatformOsIcon: FC<Props> = (props) => {
  switch (props.platformOs) {
    case "ios":
      return <AppleIcon sx={props.sx} />;
    case "android":
      return <AndroidIcon sx={props.sx} />;
  }

  return null;
};
