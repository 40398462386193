import { Box } from "@mui/material";
import { FC } from "react";
import { AppAsyncDialogs } from "./AppAsyncDialogs";
import { AppHeaderView } from "./AppHeaderView";
import { AppRouter } from "./AppRouter";

export const AppMainView: FC = () => {
  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <AppHeaderView />
      <AppRouter />
      <AppAsyncDialogs />
    </Box>
  );
};
