import { ThirdPartyProvider } from "@quickcommerceltd/zephyr-types";
import Stuart from "../../Assets/images/3p-stuart.png";
import Uber from "../../Assets/images/3p-uber.png";

export function getThirdPartyProviderImageSrc(
  thirdPartyProvider: ThirdPartyProvider
): string {
  switch (thirdPartyProvider) {
    case "STUART":
      return Stuart;
    case "UBER":
      return Uber;
  }
}
