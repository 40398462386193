import LoadingButton from "@mui/lab/LoadingButton";
import { Menu, MenuItem } from "@mui/material";
import { PickingStatus, Task } from "@quickcommerceltd/zephyr-types";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { FC, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { getTaskDocRef } from "../../Auth/helpers/getDocRef";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { logError } from "../../Common/helpers/logError";
import { TASK_COMPLETE_STATUSES } from "../constants/TASK_COMPLETE_STATUSES";
import { TASK_PICKING_STATUSES } from "../constants/TASK_PICKING_STATUSES";
import { getTaskPickingStatusTranslation } from "../helpers/getTaskPickingStatusTranslation";

interface Props {
  task: Task;
}

export const TaskPickingStatusSelect: FC<Props> = (props) => {
  const hasPermission = useHasPermission();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const filteredPickingStatuses = useMemo(
    () => TASK_PICKING_STATUSES.filter((s) => s !== props.task.pickingStatus),
    [props.task.pickingStatus]
  );

  const updateTaskPickingStatus = useCallback(
    async (pickingStatus?: PickingStatus) => {
      try {
        setAnchorEl(null);
        setIsLoading(true);

        await updateDoc(getTaskDocRef(props.task.id), {
          pickingStatus,
          updatedAt: serverTimestamp(),
        });
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [props.task.id]
  );

  const isDisabled =
    isLoading ||
    TASK_COMPLETE_STATUSES.includes(props.task.status) ||
    !hasPermission("UPDATE_TASK_PICKING_STATUS");

  return (
    <>
      <LoadingButton
        loading={isLoading}
        disabled={isDisabled}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
      >
        Change
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {filteredPickingStatuses.map((pickingStatus) => (
          <MenuItem
            key={pickingStatus}
            onClick={() => updateTaskPickingStatus(pickingStatus)}
          >
            {getTaskPickingStatusTranslation(pickingStatus)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
