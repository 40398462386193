import { FC, Fragment, ReactNode } from "react";
import { DotView } from "./DotView";

interface Props {
  items: ReactNode[];
}

export const FragmentWithDots: FC<Props> = (props) => {
  const filteredItems = props.items.filter((line) => !!line);

  return (
    <>
      {filteredItems.map((item, index) => (
        <Fragment key={index}>
          {item}
          {index !== filteredItems.length - 1 && <DotView />}
        </Fragment>
      ))}
    </>
  );
};
