import { Task } from "@quickcommerceltd/zephyr-types";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { deleteTaskCallable } from "../../Auth/callables/task/deleteTaskCallable";
import { logError } from "../../Common/helpers/logError";

export function useDeleteTask(): [(task: Task) => Promise<void>, boolean] {
  const [isDeletingTask, setIsDeletingTask] = useState(false);
  const navigate = useNavigate();

  const deleteTask = useCallback(
    async (task: Task) => {
      try {
        setIsDeletingTask(true);
        await deleteTaskCallable({ taskId: task.id });
        navigate(`/`);
      } catch (error: any) {
        toast.error(error.message);
        logError(error);
      } finally {
        setIsDeletingTask(false);
      }
    },
    [navigate]
  );

  return [deleteTask, isDeletingTask];
}
