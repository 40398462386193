import { DeliveryOption } from "@quickcommerceltd/zephyr-types/dist/types/DeliveryOption";

export function getTaskDeliveryOptionTranslation(
  deliveryOption?: DeliveryOption
): string {
  switch (deliveryOption) {
    case DeliveryOption.CALL_WHEN_AT_DOOR:
      return "Call when at door";
    case DeliveryOption.MEET_CURBSIDE:
      return "Meet curbside";
    default:
      return "Ring doorbell";
  }
}
