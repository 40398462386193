import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { TaskLog } from "@quickcommerceltd/zephyr-types";
import { FC } from "react";
import { COLOR } from "../../App/constants/COLOR";
import { LogListItem } from "./LogListItem";
import { getLogListItemProps } from "../helpers/getLogListItemProps";

interface Props {
  taskLog: TaskLog;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  onClick?: () => void;
}

export const TaskLogListItem: FC<Props> = (props) => {
  const restProps = getLogListItemProps({
    log: props.taskLog,
    isFirstItem: props.isFirstItem,
    isLastItem: props.isLastItem,
    onClick: props.onClick,
  });

  if (props.isLastItem) {
    return (
      <LogListItem
        {...restProps}
        title="Task created"
        iconColor={COLOR.gray[300]}
        iconComponent={AddCircleIcon}
      />
    );
  }

  switch (props.taskLog.update.status) {
    case "FAILED":
      return (
        <LogListItem
          {...restProps}
          title="Task failed"
          iconColor={COLOR.red[500]}
          iconComponent={CancelIcon}
        />
      );

    case "UNASSIGNED":
      return (
        <LogListItem
          {...restProps}
          title="Task unassigned"
          iconColor={COLOR.gray[300]}
          iconComponent={CircleIcon}
        />
      );

    case "DELIVERED":
      return (
        <LogListItem
          {...restProps}
          title="Task succeeded"
          iconColor={COLOR.green[500]}
          iconComponent={CheckCircleIcon}
        />
      );

    case "DELIVERING":
      return (
        <LogListItem
          {...restProps}
          title="Task started"
          iconColor={COLOR.blue[500]}
          iconComponent={ArrowCircleRightIcon}
        />
      );

    case "ASSIGNED":
      return (
        <LogListItem
          {...restProps}
          title="Task assigned"
          iconColor={COLOR.yellow[500]}
          iconComponent={CheckCircleIcon}
        />
      );

    case "PENDING_RIDER_ASSIGNMENT":
      return (
        <LogListItem
          {...restProps}
          title="Task pending"
          iconColor={COLOR.yellow[500]}
          iconComponent={CheckCircleIcon}
        />
      );
  }

  switch (props.taskLog.update.pickingStatus) {
    case "PICKING":
      return (
        <LogListItem
          {...restProps}
          title="Picking started"
          iconColor={COLOR.gray[300]}
          iconComponent={CircleIcon}
        />
      );

    case "PACKED":
      return (
        <LogListItem
          {...restProps}
          title="Picking completed"
          iconColor={COLOR.gray[300]}
          iconComponent={CheckCircleIcon}
        />
      );
  }

  if (props.taskLog.update.arrivedAtCustomerAt) {
    return (
      <LogListItem
        {...restProps}
        title="Arrived at customer"
        iconColor={COLOR.blue[500]}
        iconComponent={CheckCircleIcon}
      />
    );
  }

  if (props.taskLog.update.arrivedAtStoreAt) {
    return (
      <LogListItem
        {...restProps}
        title="Arrived at store"
        iconColor={COLOR.green[500]}
        iconComponent={ArrowCircleLeftIcon}
      />
    );
  }

  if (["API", "SYSTEM"].includes(props.taskLog.updatedBy.type)) {
    return (
      <LogListItem
        {...restProps}
        title="Task synced"
        iconColor={COLOR.gray[300]}
        iconComponent={ChangeCircleIcon}
      />
    );
  }

  return (
    <LogListItem
      {...restProps}
      title="Task edited"
      iconColor={COLOR.gray[300]}
      iconComponent={CircleOutlinedIcon}
    />
  );
};
